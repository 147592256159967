.indicator-description-tooltip {
  padding: 12px;
  line-height: 1.714;
}

.manual-updates-layout {
  .isFetching .loading-element {
    margin: 0;
  }
  .droppable-proximate-page {
    left: -12px;
    right: -12px;
    display: none;
    &.is-dragging {
      display: block;
    }
    $droppable-height: 72px;
    $droppable-space: 12px;
    position: absolute;
    z-index: 4;
    min-width: 100%;
    // background: orange;
    &[data-type="prev"] {
      top: -($droppable-height + $droppable-space);
    }
    &[data-type="next"] {
      bottom: -($droppable-height + $droppable-space);
    }
    .indicator-drop {
      height: $droppable-height;
      line-height: $droppable-height;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      // background: white;
      border-width: 1px;
      border-radius: 2px;
      color: $orange;
      background: tint($orange, 95%);
      border-color: $orange;
      &.drag-active {
        background: tint($orange, 80%);
      }
    }
  }
  // .brag {
  //   width:80px;
  // }
  // .input-brag {
  //   width:70px;
  //   text-align:center;
  // }
  .info-icon {
    font-size: 1.4rem;
    margin-top: -0.1rem;
    @include customize() {
      color: getValue(metatext);
    }
  }
  .meta {
    @include customize() {
      color: getValue(metatext);
    }
    font-size: 1rem;
    .info-icon {
      font-size: 1.1rem;
      margin-left: 0.4rem;
    }
  }
  .cell[data-id="brag"] {
    > * + * {
      margin-left: 0.8rem;
    }
  }
  .timestamp-holder {
    padding-left: 0.4rem;
    // padding-right: 0.4rem;
  }
  .cell[data-id="name"] {
    line-height: 1.6;
  }
  // .favourite-holder,
  // .id-holder {
  //   width: 1px;
  // }
  [data-id="favourite"], [data-id="drag"] {
    i.material-icons {
      font-size: 1.6rem;
    }
  }
  .cell[data-id="favourite"] {
    i.material-icons {
      &.color-lightgray {
        transition: color 0.2s ease-out;
        &:hover {
          color: tint($orange, 80%);
        }
      }
    }
  }
  // .brag-holder {
  //   // width: 25rem; //198
  //   white-space: nowrap;
  //   width: 25.4rem;
  // }
  .row {
    &.errored {
      @include customize() {
        background-color: tint(getValue(input-invalid), 94%);
      }
      + .expandable {
        @include customize() {
          background-color: tint(getValue(input-invalid), 94%);
        }
      }
    }
  }
}

[class*="theme-"] .manual-updates-layout .input.input-brag {
  width: 84px;
}

.new-value-help-icon {
  @include customize() {
    color: getValue(metatext);
  }
  font-size: 1.6rem;
  margin-left: 2px !important; 
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
  cursor: help;
  &:hover {
    opacity: 1;
  }
}

.unsavedAlert {
  #unsavedAlertDim {
    display: block !important;
  }
  .content-header {
    z-index: 11;
    > * {
      opacity: 0;
    }
    .follower {
      opacity: 1;
      top: calc(50% - 7rem);
      height: 14rem;
      padding: 0 8rem;
      transform: translateX(-50%);
      @include popover-shadow();
      p {
        font-size: 1.4rem;
      }
    }
  }
}

.flex-table {
  display: block;
  .thead, .tbody {
    display: block;
  }
  .row {
    border-collapse: collapse;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    width: 100%;
  }
  .cell {
    display: flex;
    align-items: center;
    vertical-align: top;
    flex-grow: 0;
    flex-shrink: 0;
    &.fill-space {
      flex-grow: 1;
    }
  }
}

%clear-cell-padding {
  padding-left: 0;
  padding-right: 0;
}

#mu-table {
  [data-id="drag"], [data-id="favourite"] {
    justify-content: center;
    @extend %clear-cell-padding;
  }
  [data-id="drag"] {
    width: 24px;
  }
  [data-id="favourite"] {
    width: 24px;
  }
  [data-id="id"] {
    width: 42px;
    @extend %clear-cell-padding;
  }
  [data-id="name"] {
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  [data-id="brag"] {
    width: 260px;
  }
  .row__holder {
    // &[data-temporary="true"] {
    //   pointer-events: none;
    //   .cell {
    //     color: transparent !important;
    //     > * {
    //       visibility: hidden !important;
    //     }
    //   }
    // }
    position: relative;
    &.is-dragging > * > * > * {
      opacity: 0.3;
    }
    &.active {
      .row {
        border-bottom-color: transparent;
      }
      @include customize() {
        background-color: getValue(table-active);
      }
      // + .expandable {
      //   @include customize() {
      //     background-color: getValue(table-active);
      //   }
      // }
    }
    &:not(.active) .expandable {
      display: none;
    }
    .expandable {
      .cell {
        border-top-color: transparent !important;
        padding-top: 0;
      } 
    }
    .cell [role="button"] {
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;
    }
    .drag-handle {
      &[data-disabled="true"] {
        visibility: hidden;
      }
      justify-content: center;
      align-items: center;
      cursor: move; // fallback
      cursor: grab;
      height: 100%;
      padding-left: 6px;
      > div {
        display: flex;
        align-items: center;
        border-radius: 5px;
        background: rgba(0,0,0,0);
        height: 100%;
        transition: background 0.2s ease-in-out;
      }
      &:hover > div {
        background: transparentize(black, 0.94);
      }
      &:active {
        cursor: grabbing;
        > div {
          background: transparentize(black, 0.88);
        }
      }
    }
  }
  .drag-overlay {
    .row > * {
      pointer-events: none;
    }
    .drag-handle {
      cursor: grabbing !important;
      > div {
        background: transparentize(black, 0.88);
      }
    }
    cursor: grabbing !important;
    .row__holder {
      // opacity: 0.7;
      box-shadow: transparentize($primary, $amount: 0.4) 0px 0px 0px 3px, 0 1px 0 0px #d1d5da;
      // opacity: 0.8;
      &:not(.active) {
        background: white;
      }
    }
  }
}

// .ie { 
//   .timestamp-holder {
//     position: relative;
//   }
//   .expandable {
//     .react-datepicker__time-container {
//       width: 63px;
//     }
//     // .react-datepicker__tab-loop {
//     //   width: calc(100% + 32px);
//     // }
//   }
// }