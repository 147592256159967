$home-top-height: 300px;

.home-holder {
  // display: table;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  > * {
    // display: table-row;
    // height: 1px;
    // &:first-child {
    //   display:block; //to stop expanding organisation in dashboard moving top area
    // }
    display: flex;
    align-items: center;
    &.personalized-dashboard--has-items {
      align-items: flex-start;
    }
    &:last-child {
      flex: 1;
    }
  }
}

#modal-indicator-setup article {
  > section {
    // overflow: hidden;
    min-height: 50px;
    &.left-side {
      float: left;
      // width: 30%;
      min-width: 240px;
      height: 100%;
    }
    // &:last-child {
    // }
  }
  .row {
    .icon-holder {
      i.material-icons {
        font-size: 2.4rem;
        margin: -0.2rem;
        transition: color 0.2s ease-out, opacity 0.2s ease-out;
        opacity: 1;
        // &.color-lightgray {
          // transition: color 0.2s ease-out;
          // &:hover {
          //   color: tint($green, 60%);
          // }
        // }
      }
      &.alert-holder {
        .alerts-disabled {
          opacity: 0.5;
        }
        &:hover {
          .alert-icon {
            opacity: 0.8;
          }
        }
      }
    }
    &:hover .check-holder i.material-icons.color-lightgray {
      color: tint($green, 60%);
    }
  }
  .provider-select .select__menu-list {
    max-height: 52vh !important;
  }
}

#home {
  .home_top {
    display: table;
    width: 100%;
    height: $home-top-height;
    > * {
      display: table-cell;
      vertical-align: top;
      width: 50%;
      height: 100%;
      + * {
        padding-left: 1.2rem;
      }
    }
    .home_right {
      position: relative;
      // > div {
      //   height: 100%;
      // }
      // .l-holder {
      //   position: absolute;
      // }
    }
  }
  #map {
    border-radius: $el-radius;
  }
}

.actions-holder {
  .table {
    height: 100%;
    max-height: $home-top-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // overflow:auto;
    // overflow:overlay;
  }
  .meta {
    @include customize() {
      color: getValue(metatext);
    }
    font-size: 1rem;
  }
  .tbody {
    height: calc(100% - #{$table-font-size + $table-topbottom-padding * 2});
    overflow: auto;
    // overflow: overlay;
  }
  .thead .row {
    @include customize() {
      box-shadow: 0 1px 0 0 getValue(table-border-color);
    }
  }
  .tbody .cell:last-child {
    @include customize() {
      box-shadow: 0 1px 0 0 getValue(table-border-color);
    }
    border-bottom-right-radius: 0 !important;
  }
  .cell {
    line-height: 1.66;
  }
  .showmore-cell {
    text-align: center;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.01);
    padding-top: 0;
    padding-bottom: 0;
    a {
      width: 100%;
      display: inline-block;
      height: 100%;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
  }
}

.personalized-dashboard {
  height: 100%;
  .inline-error {
    display: table-cell;
    vertical-align: middle;
    padding-top: 1.2rem;
  }
}

$organisation-width: 300px;
$organisation-margin: 6px;
$columnSize: $organisation-width + $organisation-margin * 2;
$restOfContent: 320px;
$columns: 2, 3, 4, 5, 6;

.personalized-container {
  column-count: 1;
  column-gap: 0;
  @each $col in $columns {
    @include media-min($restOfContent + $columnSize * $col) {
      column-count: $col;
    }
  }

  margin: $organisation-margin (-$organisation-margin) (-$organisation-margin)
    (-$organisation-margin);

  .organisation,
  .organisation-placeholder {
    break-inside: avoid;
    break-inside: avoid-column;
    page-break-inside: avoid;
    padding: $organisation-margin;
    display: inline-grid;
    width: 100%;
  }

  .organisation-content {
    width: 100%;
    border-radius: $el-radius;
    @include customize() {
      border: 1px solid getValue(doc-upload-border);
      background: getValue(doc-upload-bg);
    }
    padding: 1.2rem;
    // height:fit-content; //Check compatibility
    header {
      display: table;
      width: 100%;
      margin-bottom: 1.2rem;
      h3 {
        user-select: none;
      }
      > * {
        display: table-cell;
        vertical-align: middle;
        + * {
          text-align: right;
          width: 1px;
        }
      }
      .more {
        font-size: 1.8rem;
      }
    }
    &:hover .edit-area,
    &.add-new .edit-area {
      margin-bottom: 0;
      max-height: 4rem;
      opacity: 1;
    }
  }

  .indicator-list {
    > .indicator-item {
      @include customize() {
        border: 1px solid getValue(border-gray);
        background: getValue(home-indicator);
      }
      border-radius: $el-radius;
      padding: 1.2rem;
      cursor: pointer;
      display: table;
      width: 100%;
      > div {
        display: table-cell;
        vertical-align: middle;
        &:last-of-type {
          text-align: right;
        }
        &:first-child {
          padding-right: 0.8rem;
        }
      }
      > .row-mobile {
        display: table-row;
        font-size: 1rem;
        > div {
          padding-top: 0.4rem;
          text-align: left;
          display: table-cell;
        }
      }
      // .mobile-on,
      // .mobile-off {
      //   text-transform: uppercase;
      // }
      .mobile-on {
        color: $green;
      }
      .mobile-off {
        color: $red;
      }
      + li {
        margin-top: 0.8rem;
      }
    }
    .name {
      font-size: 1.2rem;
      @include customize() {
        color: getValue(subtext);
      }
      display: block;
      margin-bottom: 2px;
    }
    .meta {
      font-size: 1rem;
      @include customize() {
        color: getValue(metatext);
      }
    }
    .edit-area {
      color: $blue;
      font-weight: bold;
      text-align: center;
      height: 4rem;
      line-height: 4rem;
      cursor: pointer;
      overflow: hidden;
      transition: max-height 0.3s ease-out, opacity 0.2s ease-out,
        background-color 0.2s ease-out, border-color 0.2s ease-out;
      font-size: 1.1rem;
      // margin-bottom:-0.8rem;
      // max-height:0;
      // opacity:0;
      margin-bottom: 0;
      max-height: 4rem;
      opacity: 1;
      user-select: none;
      &:hover {
        @include customize() {
          background-color: tint(getValue(doc-upload-bg), 50%);
        }
        border-color: $blue;
      }
    }
  }

  .organisation-placeholder {
    height: 0; ///2px;
    background-color: red;
  }
}

@supports (break-inside: avoid-column) {
  .personalized-container {
    .organisation {
      display: block;
    }
  }
}

// EDGE:
@supports (break-inside: avoid-column) and (-ms-ime-align: auto) {
  .personalized-container .organisation,
  .personalized-container .organisation-placeholder {
    display: inline-block;
  }
}

// .ie {
//   .actions-holder .table {
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }

// .personalized-container .organisation:nth-child(2) {
//   display: block;
// }

// @-moz-document url-prefix() {
//   .personalized-container {
//     .organisation-content .edit-area {
//       margin-bottom:0 !important;
//       max-height:4rem !important;
//       opacity:1 !important;
//     }
//   }
// }

// @Edge | IE10, IE11
// #HomeFix1
