[data-value^="speciality_"] span {
  color: #333;
  // border-bottom: 1px dashed #333;
  // box-shadow: inset 0 -1px 0 0 red;
}

[data-value^="sub_"] {
  margin-left: 16px;
  position: relative;
  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #aaa;
    border-left: 1px solid #aaa;
    margin-left: -10px;
    width: 12px;
    height: 14px;
    margin-right: 3px;
    margin-top: -2px;
  }
  label {
    margin-left: 6px;
  }
}
// [class^="theme-"] #elective-home {
//   .select.indicator-select .select__menu-list {
//     max-height: calc(100vh - 230px) !important;
//   }
//   .has-error.indicator-select .select__control {
//     border-color: $red;
//   }
//   .popover-brag-table .tbody {
//     padding-right: 6px;
//   }
// }
