// $invalid-bg:tint($red, 90%);
// $invalid-bg-hover:tint($red, 85%);
// $invalid-border:$red;

// $full-error-padding: 6rem;
$full-error-padding: 3.33vw;


[class*="is-invalid"] {
  &,
  &:hover,
  &:focus {
    @include customize() {
      border-color: getValue(input-invalid) !important;
      background: transparentize(getValue(input-invalid), 0.9) !important;
    }
  }
  &:hover {
    @include customize() {
      background: transparentize(getValue(input-invalid), 0.85) !important;
      box-shadow: 0 0 0 1px getValue(input-invalid-border-1) !important;
    }
  }
}

.form-error {
  display: none;
  @include customize() {
    color: getValue(input-invalid) !important;
  }
  font-size: 1.2rem;
  margin-top:0.4rem;
  &:empty {
    margin-top: 0;
  }
  &.is-visible {
    display: block;
  }
}

#full-error {
  padding-bottom: 0;
}

.full-error-container {
  height: 100%;
  &:not(.full-error-container--internal) {
    @include customize() {
      background: shade(getValue(sidebar-color-bg), 20%);
      // color: getValue(sidebar-color);
    }
    h1,
    p {
      color: white;
    }
  }
  pre.error-debug {
    word-break: break-all;
    code {
      display: block;
      margin: 12px 0 16px;
      padding: 16px 8px;
      font-size: 12px;
      @include customize() {
        color: getValue(breadcrumbs-text);
      }
    }
  }
  h1 {
    font-size: 4.3rem;
    margin-bottom: 1.2rem;
  }
  p {
    font-family: $font-family-heading;
    font-weight: 300;
    font-size: 2.1rem;
    + .btn {
      margin-top: 3.4rem;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  section {
    flex-grow: 1;
    > div:nth-child(2) {
      width: 40%;
    }
  }
  section,
  footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem $full-error-padding;
    gap: 8px;
  }
  footer {
    // background: white;
    // color: $darknavy;
    @include customize() {
      color: getValue(body-color);
      background: getValue(body-bg);
    }
    height: 174px;
  }
  .meta {
    @extend %footer-contact;
  }
  .badges {
    @extend %badges;
  }
  .logo {
    @extend %logo;
    position: absolute;
    top: $full-error-padding;
    right: $full-error-padding;
    top: 6vh;
    right: 6vh;
    // .cls-1 {
    //   fill: white !important;
    // }
  }
  .image-holder {
    // margin:0 0 0 auto;
    margin: 0 auto;
    max-width: 34vh;
    // width: 70%;
    width: 100%;
    min-width: 120px;
  }
  .full-error-details {
    word-break: break-all;
    padding: 0.8rem;
    margin-top: 1.2rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    code {
      font-size: 1rem;
      padding: 0;
      background: transparent;
      color: white;
    }
  }
  &--internal {
    section, footer {
      @include customize() {
        background: white;
      }
    }
    // .image-holder svg > * {
    //   fill: black !important;
    // }
  }
}
