select::-ms-expand {
  display: none;
}

input::-ms-clear {
  display: none;
}

@supports (-ms-ime-align: auto) {
// .ie {
  .ms-overflow-style-auto {
    -ms-overflow-style: auto !important;
  }
}
// loaders
// .ie {
//   #safer-form-wrapper {
//     .l-holder {
//         text-align:center;
//     }
//   }
//   .personalized-dashboard {
//     .l-holder {
//       padding-top: 80px;
//     }
//   }
// }


// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE 10, IE 11
  // #holder {
  //   overflow: auto;
  // }
  // .l-holder {
  //   text-align: center;
  // }
  // #HomeFix1
  // .organisation-content .edit-area {
  //   margin-bottom:0 !important;
  //   max-height:4rem !important;
  //   opacity:1 !important;
  // }
// }

@supports (-ms-ime-align: auto) {
  // Edge
  // #HomeFix1
  // .organisation-content .edit-area {
  //   margin-bottom:0 !important;
  //   max-height:4rem !important;
  //   opacity:1 !important;
  // }
  // #HomeFix2
  .organisation-content {
    position: relative;
    header > .dropdown-container {
      position: absolute;
      right: 2.4rem;
      z-index: 3;
    }
  }
}

// .ie {
//   .personalized-container .organisation,
//   .personalized-container .organisation-placeholder {
//     display: inline-block;
//   }
// }

@supports (-ms-ime-align: auto) {
  .personalized-container .organisation-content header h3 {
    width: 98%;
  }
}
