.login {
  --branding-size: 10px;
  --login-padding-left: 4.6rem;
  --login-padding-right: 8rem;
  --login-header-height: calc(var(--login-padding-right) + 1.2rem);
}

.login .language-switcher .dropdown {
  bottom: 4rem;
}

%footer-contact {
  font-size: 1.2rem;
  h6 {
    margin-bottom: 2px;
    letter-spacing: 0.06rem;
  }
  i.material-icons {
    font-size: 1.4rem;
    padding-right: 0.4rem;
  }
  li {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
  }
}

%badges {
  justify-content: flex-end;
  text-align: right;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  a {
    display: inline-block;
    height: 40px;
    &.enclosed {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: black;
      border-radius: 6px;
      width: 130px;
    }
    img {
      height: 100%;
    }
    // &.app-store-badge {
    //   width: 120px;
    // }
    // + a {
    //   margin-left: 0.4rem;
    // }
  }
}

%logo {
  // text-align:right;
  // width: 150px;
  max-width: 180px;
  width: 100%;
  // display:inline-block;
  // float: right;
  margin-left: auto;
  // .cls-1 {
  //   fill: $primary;
  //   transition: fill 0.3s ease-out;
  // }
  // &:hover {
  //   .cls-1 {
  //     fill: shade($primary, 10%) !important;
  //   }
  // }
}

.login {
  .logo__wrapper svg {
    width: auto;
    height: 100%;
  }
  height: 100vh !important;
  display: flex;
  width: 100%;
  .side {
    // display: table-cell;
    // vertical-align: middle;
    // width:50%;
    &.right {
      padding: 0 var(--login-padding-right);
    }
    &.left {
      flex-grow: 1;
      padding: 0 var(--login-padding-left);
    }
    // .left section {
    //   display: table;
    //   width: 100%;
    //   height: 100%;
    // }
    section {
      height: 100%;
      width: 100%;
    }
    &.right section {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
    // &.right footer,
    // &.right .middle,
    // header {
    //   display: table-row;
    //   > * {
    //     display: table-cell;
    //     vertical-align: middle;
    //     // padding:$login-padding 0;
    //   }
    // }
    &.right .middle {
      // height: auto;
      flex-grow: 1;
      display: flex;
      align-items: center;
      > * {
        width: 100%;
      }
    }
    // &.right footer,
    // header {
    //   height: 1px;
    // }
    &.left .middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &.left footer {
      position: absolute;
      bottom: 0;
    }
    footer {
      font-size: 1.4rem;
      h6 {
        font-size: 1.6rem;
      }
      // > * {
      //   padding-bottom: $login-padding * 2;
      // }
    }
    &.left footer > * {
      padding-bottom: var(--login-padding-left);
    }
    // &.left footer > * {
    //   padding-bottom: $login-padding-left * 2;
    // }
    // &.right footer > * {
    //   padding-bottom: $login-padding-right * 2;
    // }
    header {
      align-items: flex-end;
      max-height: var(--login-header-height);
      flex-grow: 1;
      min-height: 64px;
    }
    &.right footer {
      align-items: flex-start;
      > * {
        padding-bottom: 24px;
      }
      // padding-bottom: 18px;
      // height: 98px;
    }
    header, &.right footer {
      display: flex;
      flex-direction: row;
      flex-shrink: 1;
      > * {
        display: flex;
        width: 100%;
        align-items: flex-end;
      }
      // > * {
      //   padding-top: var(--login-padding-right);
      // }
    }
  }
  .branding__container {
    font-size: var(--branding-size);
  }
  > .left {
    // width:56%;
    .logo__wrapper {
      width: 50em;
      // margin: 0 auto;
      // width: 500px;
      // width: clamp(256px, 500px, 35.8vw);
      // display: flex;
      // font-size: 0;
    }
    color: white;
    @include customize() {
      background: getValue(sidebar-color-bg);
    }
    line-height: 1.66;
    h1,
    h6 {
      color: white;
    }
    h1 {
      font-size: 4.2rem * 1.2;
      letter-spacing: 0.6px;
      // line-height:1.1;
      // margin-bottom:1.2rem;
    }
    p {
      color: #64C0BC;
      text-align: center;
      font-size: 3em;
      margin-top: 1.24em;
      // font-size: 1.8rem;
      // font-size: 32px;
      // font-size: clamp(18px, 1.8vw, 2.2vw);
      // margin-top: 40px;
      // margin-top: clamp(18px, 2.3vw, 2.4vw);
    }
    @extend %footer-contact;
  }
  > .right {
    width: 44%;
    min-width: 42rem;
    overflow: auto;
    scrollbar-gutter: stable both-edges;
    @include customize() {
      background: getValue(box-bg);
    }
    // background-color: white;
    // > section {
    //   background-image: url(#{$ASSETS-PATH}/ts-logo.svg);
    //   background-repeat:no-repeat;
    //   background-position:right 4.8rem;
    //   background-size: 150px 40px;
    // }
    h3 {
      font-size: 3.4rem;
      margin-bottom: 3.2rem;
    }
    .login-form > div {
      margin-top: 3.8rem;
    }
    .login-form {
      .login-input {
        margin-top: 2.4rem;
      }
      > .form-error__reset {
        + .reset-input {
          margin-top: 0;
        }
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
      }
      // .login-input .input + .form-error__reset {
      //   margin-bottom: 0;
      //   margin-top: 0.8rem;
      // }
      .reset-requirements-section {
        margin-top: 0;
      }
    }
    .logo {
      display: none;
      @extend %logo;
    }
  }
  .badges {
    @extend %badges;
  }
  .form-error {
    font-size: 1.4rem;
  }
  a.login-back-btn {
    opacity: .7;
    transition: opacity .3s ease-in-out;
    display: inline-block;
    padding-right: 20px;
    @include customize() {
      color: getValue(text);
    }
    i {
      transition:transform .3s ease-in-out;
      transform: translateX(0);
    }
    &:hover {
      opacity:1;
      i {
        transform: translateX(-5px);
      }
    }
  }
  .footer-split {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    width: 100%;
    .dropdown-trigger { display: inline-block; }
    .language-switcher + .badges {
      margin-left: auto;
    }
    // > div {
    //   display: table-cell;
    //   vertical-align: middle;
    // }
    .language-switcher .dropdown {
      min-width: 140px;
    }
  }
}

// @include media-max(480px) {
//   .login {
//     .left {display:none;}
//     .right {
//       width:100%;
//     }
//     .badges {
//       text-align:center;
//     }
//   }
// }

@include media-max(1600px) {
  .login {
    --branding-size: 8px;
    --login-padding-left: 2.3rem;
    --login-padding-right: 3.6rem;
  }
}

@include media-max(1280px) {
  .login {
    --login-padding-right: 1.8rem;
  }
}

@include media-max(960px) {
  .login {
    --branding-size: 7px;
    --login-padding-right: 1.1rem;
  }
}

@include media-max(860px) {
  .login {
    --branding-size: 4.6px;
  }
}

@include media-max(720px) {
  .login {
    .side.left {
      display: none;
    }
    .side.right {
      width: 100%;
      .logo {
        display: inline-block;
      }
    }
    // .badges {
    //   text-align: center;
    // }
    // .language-switcher ~ .badges {
    //   text-align: right;
    // }
    .footer-split {
      flex-direction: column;
      gap: 24px;
      .language-switcher + .badges {
        margin-left: initial;
      }
    }
  }
}

@include media-min(1921px) {
  .login {
    --branding-size: 12px;
    --login-padding-left: 9.2rem;
    --login-padding-right: 10.4rem;
  }
}

@include media-min(2380px) {
  .login {
    .right.side {
      width: 38%;
      // padding:0 $login-padding * 6;
    }
  }
}

.login {
  ~ #sidebar,
  ~ #holder {
    display: none !important;
  }
}
