#cookieNotice {
  &.hidden {
    display: none !important;
  }
  header {
    margin-bottom: 1.8rem;
    h2 {
      margin-bottom: 0;
    }
  }
  footer {
    margin-top: 2.4rem;
    text-align: right;
  }
  max-width: 420px;
  height: auto;
  width: auto;
  @include popover-shadow();
  position: fixed;
  z-index: 1;
  right: 3.2rem;
  bottom: 3.2rem;
  // background: rgba(0, 0, 0, 0.3);
}

#biscuitSettingsModal {
  .biscuitItem {
    .checkbox > span {
      font-weight: bold;
    }
    .biscuitItemHeader {
      margin-bottom: 0.8rem;
    }
    + .biscuitItem {
      margin-top: 2.4rem;
    }
  }
  .biscuitMeta {
    margin-top: 2.4rem;
    font-weight: 600;
    color: $navy;
  }
  footer {
    display: table;
    width: 100%;
    > div {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      text-align: left;
      &:last-child {
        text-align: right;
      }
    }
  }
}
