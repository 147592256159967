@use "sass:color";

$thumb-bg: #EEF0F2;

.grid {
  &__container {
    --grid-item-min-width: 224px;
    --grid-gap: 24px;
    --grid-meta-height: 50px;
    --grid-meta-min-height: 0;
    --grid-thumbnail-bg: #{$thumb-bg};
    --grid-thumbnail-bg-hover: #{color.mix(#EEF0F2, $primary, 90%)};
    --grid-thumbnail-height: 180px;
    --grid-meta-spacing: 2px;
    display: grid;
    gap: var(--grid-gap);
    grid-template-columns: repeat(auto-fill, minmax(var(--grid-item-min-width), 1fr));
    &--hoverable {
      .grid__content {
        &:hover, &:focus-within {
          .grid__thumbnail {
            background-color: var(--grid-thumbnail-bg-hover);
            border-color: $primary;
          }
          .h3 {
            color: $primary;
          }
        }
      }
    }
  }
  &__item {
    height: 100%;
    width: 100%;
    display: inline-block;
    // will-change:transform;
    transform: translateZ(0);
    transition: transform 0.3s ease-out;
    .modal-trigger {
      display: contents;
    }
    &[data-type="video"] {
      .grid--thumbnail {
        background-color: black;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
    line-height: normal;
    &:hover {
      text-decoration: none;
    }
  }
  &__thumbnail {
    height: var(--grid-thumbnail-height);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    border-radius: 8px;
    background-color: var(--grid-thumbnail-bg);
    flex: 0 0 auto;
    border: 1px solid rgba(0, 17, 43, 0.11); // todo
    transition: border-color 0.12s ease, background-color 0.12s ease;
    &[data-thumbnail="false"] {
      background-size: auto;
    }
  }
  &__meta {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: var(--grid-meta-height);
    min-height: var(--grid-meta-min-height);
    width: 100%;
    gap: var(--grid-meta-spacing);
    .h3 {
      font-weight: 400;
      font-size: 1.6rem;
      margin: 0;
      transition: color 0.12s ease;
    }
    .p {
      font-size: 1.2rem;
    }
  }
}