@use "sass:color";

#my-account {
  .alert-table-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn {
      white-space: nowrap;
    }
  }
  .constrain-text {
    margin-right: 0.4rem;
    margin-right: 1.2vw;
    font-size: 1.2rem;
    @include customize() {
      color: getValue(metatext);
    }
  }
}

.account-section {
  hr {
    margin-top: 0;
  }
  h2 {
    margin-bottom: 2.4rem;
  }
  header {
    display: table;
    width: 100%;
    margin-bottom: 1.2rem;
    > * {
      display: table-cell;
      vertical-align: bottom;
      + * {
        text-align: right;
      }
    }
  }
  section {
    display: table;
    width: 100%;
    // height:100%;
    > * {
      display: table-cell;
      vertical-align: top;
      &:first-child {
        width: 34%;
      }
      + * {
        padding-left: 4.8rem;
      }
    }
  }
  p, aside .list li {
    @include customize() {
      color: getValue(metatext);
    }
    font-size: spToRem(12);
    margin-top: -0.4rem;
    + p {
      margin-top: 0.8rem;
    }
  }
  &.password-requirements-section {
    aside ul.list li { margin-top: 0; }
  }
  + .account-section {
    margin-top: 4.8rem;
  }
  .mobile__holder {
    position: relative;
    display: flex;
    > .select {
      max-width: 104px;
      position: static;
      // .select__menu {
      //   width: 200%;
      // }
    }
  }
}

.account-form {
  > div {
    margin-bottom: 1.8rem;
  }
  footer {
    text-align: right;
    .btn {
      padding: 0 3.2rem;
    }
  }
}

//////// Days/hours
.days-hours {
  margin-top: -0.8rem;
  > div {
    border-top: 1px dashed transparent;
    border-bottom: 1px dashed transparent;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: transparentize($primary, 0.96);
    }
    &.isWeekend {
      background-color: transparentize($primary, 0.93);
      &:hover {
        background-color: transparentize($primary, 0.89);
      }
    }
    display: table;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
      padding: 0.8rem 1.2rem;
      &:last-child {
        text-align: right;
      }
      > * {
        vertical-align: middle;
      }
    }
  }
  label.checkbox {
    font-weight: 600;
    margin-bottom: 0;
    width: 100%;
    cursor: pointer;
  }
  // .input {
  //   width: 54px;
  //   text-align: center;
  // }
  .minus {
    padding: 0 0.4rem;
    height: $medium-height;
    display: inline-block;
    &:before {
      content: "-";
      display: inline-block;
      @include heightLine($medium-height);
    }
  }
  // .hour-holder {
  //   display:inline-block;
  // }
  .react-datepicker__tab-loop {
    display: inline-block;
  }
}

// .ie {
//   .react-time-wrapper {
//     position: relative;
//   }
// }


.isFetching .loading-element {
  &.react-time-wrapper {
    width: 76px;
    height: $medium-height;
    margin: 0;
  }
}

// secutity section
$mfa-option-radius: 8px;
.account-section.security-section {
  .welcome__option {
    &__wrapper {
      &[data-enabled="true"] {
        .mfa__options__item {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-color: $green;
          &:hover {
            background: inherit;
          }
        }
      }
    }
    &__buttons {
      display: flex;
      gap: 4px;
    }
    &__btn {
      padding: 0.4rem;
      cursor: pointer;
      background: rgba(0,0,0,0.06);
      border-radius: 50%;
    }
    &__footer {
      padding: 0 2.6rem;
      font-size: 1.2rem;
      display: flex;
      width: 100%;
      background: color.change($green, $alpha: 0.10);
      align-items: center;
      justify-content: space-between;
      height: 48px;
      border: 1px solid $green;
      border-top-width: 0;
      border-bottom-left-radius: $mfa-option-radius;
      border-bottom-right-radius: $mfa-option-radius;
      &__side {
        display: flex;
        align-items: center;
        gap: 4px;
        &:first-child {
          margin-left: -1.2rem;
        }
        &:last-child {
          margin-right: -1.2rem;
        }
      }
    }
    &__favourite {
      i {
        transition: color 0.2s ease-in-out;
      }
      &:hover {
        i {
          color: $orange;
        }
      }
      &:disabled {
        color: $orange;
      }
    }
    &__enabled {
      color: $green;
      display: inline-flex;
      gap: 2px;
      font-size: 1.2rem;
      align-items: center;
      i {
        font-size: 1.4rem;
      }
    }
  }
}