// Default html font-size = 62.5%;
// 16px = 16/10 rem = 1.6 rem
//

$sidebar-padding-total: $sidebar-padding + $sidebar-padding;

#sidebar {
  width: $sidebar-max-width;
  #product-select-holder ul.product-dropdown {
    // right: $sidebar-padding;
    min-width: $sidebar-max-width - $sidebar-padding-total;
  }
}

.sticky {
  &.title-in-header {
    #title {
      // transition: opacity 0.4s ease-out, margin-left 0.3s ease;
      // margin-left: $sidebar-collapsed-width;
      margin-left: $sidebar-max-width;
      > div {
        transform: translate(0, 0);
        transition: transform 0s ease;
      }
    }
    #sidebar, #x {
      &:hover ~ #holder #title > div {
        transition: transform 0.3s ease;
      }
    }
    .is-collapsed ~ #holder #title {
      > div {
        transform: translate(-($sidebar-max-width - $sidebar-collapsed-width), 0);
      }
      // margin-left: $sidebar-collapsed-width;
    }
  }
}

// #holder,
// .sticky.title-in-header #title {
//   margin-left: $sidebar-max-width;
// }
// .sticky:not(.title-in-header) #header-viewport-container {
//   margin-left: -($sidebar-max-width);
// }

// (100 / 90) * 1504 = 1671.1~
// zoom for surface pro
@include media-max($breakpoint-alias-med-sidebar) {
  // #sidebar {
  //   width: $sidebar-med-width;
  //   #product-select-holder ul.product-dropdown {
  //     // right: -2.4rem;
  //     min-width: $sidebar-med-width - $sidebar-padding-total;
  //   }
  // }

  // #holder,
  // .sticky.title-in-header #title {
  //   margin-left: $sidebar-med-width;
  // }
  // .sticky:not(.title-in-header) #header-viewport-container {
  //   margin-left: -($sidebar-med-width);
  // }
}

@include media-max($breakpoint-lPlus) {
  html {
    font-size: 60%;
  }
  // #sidebar {
  //   width: $sidebar-min-width;
  // }

  // #holder,
  // .sticky.title-in-header #title {
  //   margin-left: $sidebar-min-width;
  // }
  // .sticky:not(.title-in-header) #header-viewport-container {
  //   margin-left: -($sidebar-min-width);
  // }
}

@include media-max($breakpoint-l) {
}

@include media-max(1367px) {
  // html {font-size:60%;}
}

@include media-max($breakpoint-m) {
  html {
    font-size: 56%;
  }
}

@include media-max($breakpoint-xs) {
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Retina-specific stuff here */
}
