.switch-control__wrapper {
  --switch-colour-on: #{$blue};
  --switch-colour-off: #dee2e6;
  --switch-colour: var(--switch-colour-off);
  --switch-calculated-colour-secondary: var(--switch-colour-off);
  --switch-height: 32px;
  --switch-width: 60px;
  --switch-radius: 4px;
  --switch-slider-radius: 2px;
  --switch-edge-margin: 4px;
  --switch-bottom-padding: 1px;
  --switch-calculated-slider-height: calc(var(--switch-height) - var(--switch-edge-margin) * 2);
  --switch-slider-width: calc(var(--switch-width) / 2 - var(--switch-edge-margin));
  --switch-calculated-slider-on-right: var(--switch-edge-margin);
  --switch-calculated-slider-off-right: calc(var(--switch-width) - calc(var(--switch-edge-margin) + var(--switch-slider-width)));
  &--brag {
    --switch-width: 42px;
    --switch-height: 19px;
    --switch-slider-radius: 50%;
    --switch-radius: 12px;
    --switch-slider-width: 11px;
    height: 32px; // ! temp
  }
  position: relative;
  border-bottom: 1px solid var(--switch-colour); 
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background-color: color-mix(in srgb, var(--switch-colour) 10%, white);
  // &:hover {
  //   background-color: color-mix(in srgb, var(--switch-colour) 14%, white);
  // }
  &:has(input:checked) {
    --switch-colour: var(--switch-colour-on);
  }
}

.switch-control {
  // position: relative;
  font-size: 0;
  input[type='checkbox'] {
    position: absolute;
    margin: 0;
    z-index: 1;
    inset: 0;
    cursor: pointer;
    opacity: 0;

    & ~ em {
      display: inline-block;
      width: var(--switch-width);
      position: relative;
      line-height: var(--switch-height);
      // margin: calcPx(0.2) 0;
      // cursor: pointer;
      // user-select: none;
      // text-indent: -9999px;
      height: var(--switch-height);

      &:before, &:after {
        // right: 0;
        position: absolute;
        display: block;
        top: 0;
        // bottom: 0;
        content: ' ';
        // background-color: $brag-black;
        // border-radius: 3px;
        transition: all 100ms ease-in;
        // background-size: 20px 9px;
      }

      &:before {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: var(--switch-radius);
        // border: 1px solid #CFD4D8;
      }

      &:after {
        right: var(--switch-calculated-slider-off-right);
        // width: calcPx(1.75);
        width: var(--switch-slider-width);
        top: var(--switch-edge-margin);
        bottom: var(--switch-edge-margin);
        // margin-left: calcPx(0.2);
        background-color: #fff;
        border-radius: var(--switch-slider-radius);
        box-shadow: 0 1.6px 0 rgba(0,0,0,0.2);
        // border: 1px solid #A7A9AC;
        font-weight: 900;
        line-height: 0.9375em;
        text-align: center;
        font-size: 16px;
      }
    }

    &:checked {
      & ~ em:after {
        right: var(--switch-calculated-slider-on-right);
      }
    }
    &:focus-visible {
      & ~ em:before {
        outline: 2px solid #275dc5;
      }
    }
  }
}

.switch-control {
  input[type='checkbox'] {
    ~ em {
      &:before {
        background-color: var(--switch-colour);
        box-shadow: inset 0 calc(var(--switch-bottom-padding) * -1) 0 0 var(--switch-colour);
      }
      &:after {
        background-color: color-mix(in srgb, var(--switch-colour) 10%, white);
        // background-color: mix(white, $brag-black, 90%);
        // background-color: rgba(33, 42, 65, 0.1);
        color: var(--switch-colour);
      }
    }
  }
}
.switch-control {
  input[type='checkbox'] {
    ~ em {
      &:after {
        // font-size: 13px;
        // line-height: 23px;
        font-size: calc(var(--switch-calculated-slider-height) / 2 + var(--switch-bottom-padding));
        line-height: calc(var(--switch-calculated-slider-height) - var(--switch-bottom-padding));
        content: attr(data-slider-text-off);
      }
      &[data-slider-text="auto"]:after {
        content: '...';
        color: #5F6062;
      }
    }
    &:checked ~ em[data-slider-text-on] {
      &:after {
        content: attr(data-slider-text-on);
      }
    }
  }
}

// .switch-control {
//   input[type='checkbox'] {
//     & ~ em {
//       &:before {
//         content: attr(data-text-off);
//         text-indent: calcPx(2.15);
//         text-align: left;
//         color: #fff;
//         font-size: calcPx(0.5);
//         font-weight: 900;
//       }
//     }
//     &:checked ~em:before {
//       content: attr(data-text-on);
//       text-indent: calcPx(0.5);
//     }
//   }
// }