%trigger-btn-hover {
  @include customize() {
    color: getValue(primary);
    border-color: getValue(primary);
    background: getValue(follower-bg);
  }
}

.data-toolbar {
  display: flex;
  &__filters {
    display: flex;
    gap: 8px;
    &__limited-dropdown {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      max-height: 60vh;
      overflow: auto;
      // scrollbar-gutter: stable;
      width: max-content;
      max-width: 400px;
      min-width: 240px;
      padding: 0.8rem;
      margin: -0.8rem;
      margin-bottom: 0;
      li {
        display: flex;
        a {
          flex: 1 1 auto;
          padding: 0;
          padding-left: 0.8rem;
        }
        label {
          cursor: pointer;
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
          padding-right: 0.8rem;
          width: 100%;
        }
        .checkbox > span {
          height: auto;
        }
      }
    }
  }
  &__divider {
    width: 1px;
    height: 40%;
    margin: 0 8px;
    opacity: 0.8;
    @include customize() {
      background: getValue(input-border);
    }
  }
  > * {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    &:last-child {
      margin-left: auto;
    }
  }
  &__trigger.btn, &__trigger__clear.btn {
    @include customize() {
      color: getValue(label);
    }
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    &:hover {
      color: $primary;
    }
  }
  &__trigger.btn {
    gap: 4px;
    border-radius: 8px;
    white-space: nowrap;
    @include customize() {
      border: 1px solid getValue(table-border-color);
      background: getValue(table-bg);
    }
    &:hover, &.is-active {
      @extend %trigger-btn-hover;
    }
    // &.is-active:not(:hover) {}
  }
  &__trigger__clear.btn {
    padding: 0 1.6rem;
    margin: 0 -1.6rem;
  }
  @at-root .btn i.data-toolbar__trigger__icon {
    margin-left: -0.4rem;
    opacity: 0.5;
    font-size: 1.8rem;
  }
  &__chevron {
    padding-left: 0.4rem;
    transition: transform 0.2s ease;
    margin-top: 1px;
    margin-right: -4px;
  }
  &__dropdown {
    .dropdown {
      border-radius: 4px;
      margin-top: 4px;
      overflow: hidden;
      z-index: 4;
    }
    &:has(.dropdown.is-open) {
      .data-toolbar__chevron {
        transform: scaleY(-1);
      }
      .data-toolbar__trigger {
        @extend %trigger-btn-hover;
      }
    }
  }
  .react-datepicker {
    box-shadow: none;
  }
  .dropdown i.data-toolbar__sort__type__icon {
    @include customize() {
      color: getValue(metatext);
    }
    opacity: 0.6;
    font-size: 14px;
  }
}

.data-toolbar__sort {
  &__item {
    &:hover {
      @include customize() {
        background: transparentize(getValue(input-border), 0.9);
      }
      .data-toolbar__sort__icon__wrapper {
        opacity: 0.3;
        svg[data-pack="orion"][data-type="line"] {
          [data-name="layer1"] {
            transform: scaleX(0.44);
          }
        }
      }
    }
    border-radius: 2px;
    cursor: pointer;
    min-width: 280px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.8rem 1rem;
    &[data-order="DESC"], &[data-order="ASC"] {
      .data-toolbar__sort__icon__wrapper, .data-toolbar__sort__description {
        opacity: 1;
      }
      .data-toolbar__sort__icon__wrapper svg[data-pack="orion"][data-type="line"] {
        [data-name="layer1"] {
          transform: scaleX(1);
        }
      }
    }
    &[data-order="ASC"] {
      .data-toolbar__sort__icon__wrapper svg {
        transform: scale(-1);
      }
    }
    &[data-order="DESC"] {
      .data-toolbar__sort__icon__wrapper svg {
        transform: scale(1);
      }
    }
    &[data-type="date"] {
      .data-toolbar__sort__type__icon {
        margin-top: -1px;
      }
    }
  }
  &__description {
    font-size: 1.1rem;
    @include customize() {
      color: getValue(metatext);
    }
    [aria-hidden="true"] {
      display: none;
    }
  }
  &__label {
    font-size: 1.3rem;
    // font-weight: 500;
    display: block;
    + * {
      margin-left: auto;
    }
  }
  &__icon__wrapper, &__description {
    transition: opacity 0.2s ease;
    opacity: 0;
  }
  &__icon__wrapper {
    width: 24px;
    margin-right: -4px;
    svg[data-pack="orion"][data-type="line"] {
      transition: transform 0.2s ease;
      [data-name="layer1"] {
        transition: inherit;
        transform: scaleX(0);
        transform-origin: center;
      }
      path {
        @include customize() {
          stroke: getValue(primary);
        }
      }
    }
  }
}