html {
  &[lang="fr-CA"] {
    .react-datepicker {
      .react-datepicker__current-month {
        text-transform: capitalize;
      }
      .react-datepicker__day-name {
        text-transform: capitalize;
      }
    }
  }
}