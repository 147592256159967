.head {
  height: $header-height;
}

#header {
  // height:1px; //firefox fix
  // display: table-header-group;
}

#header-holder {
  @include customize() {
    color: getValue(header-text);
    background: getValue(header-bg);
    box-shadow: getValue(header-shadow);
  }
  // box-shadow:0 1px 0 0 rgba(0,0,0,0.1);
  font-family: $font-family-heading;
  font-weight: 500;
  font-size: spToRem(14);
  user-select: none;
  transition: box-shadow 0.4s ease;
  position: relative;
  z-index: 5;
  a:hover {
    text-decoration: none;
  }
  .notification-tag .tag {
    position: absolute;
    right: 6px;
    top: 14px;
    border: 2px solid transparent;
    @include customize() {
      border-color: getValue(header-bg);
    }
    &:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      border: 2px solid transparentize($red, 0.5);
      opacity: 0;
      border-radius: spToRem(24);
      width: 100%;
      height: 100%;
      animation: sonarWithDelay 5s infinite;
      // animation-delay: 6s;
    }
  }
  //> * {
  // height:100%;
  // display:table-row;
  // height:1px;
  height: $header-height;
  ul.head {
    // overflow:hidden;
    // height:100%;
    padding-left: spToRem(24);
    // width:50%;
    display: inline-block;
    // @include verticalMiddleHack();
    > li {
      float: left;
      height: 100%;
      position: relative;
      // display:table-cell;
      // vertical-align:middle;
      // font-size:14px;
      > * {
        display: block;
        text-align: center;
        padding: 0 spToRem(12);
        height: 100%;
        @include verticalMiddleHack();
      }
      > a {
        @include customize() {
          color: getValue(header-text);
        }
        transition: background-color 0.3s ease;
        &:hover {
          @include customize() {
            background-color: transparentize(getValue(header-bg), 0.97);
          }
        }
        > i.material-icons {
          font-size: spToRem(30);
        }
      }
    }
    // vertical-align: middle;
    &:last-child {
      // text-align:right;
      padding-left: 0;
      padding-right: spToRem(12);
      float: right;
      position: sticky;
      right: 0;
    }
  }
  //}

  .user-profile .dropdown {
    // display:none;
    // // max-height:0;
    // opacity:0;
    // overflow: hidden;
    position: absolute;
    // width:100%;
    // height:80px;
    transition: opacity 0.15s cubic-bezier(0.25, 0, 0.25, 1.75); //, opacity .1s linear;
    &:before {
      content: initial;
    }
    margin-top: spToRem(-12);
    // border-radius:$el-radius;
    // box-shadow: 0 5px 13px rgba(0, 0, 0, 0.15);
    width: auto;
    height: auto;
    right: 0;
    min-width: 240px;
    padding: spToRem(8);
    text-align: left;
  }

  // .user-profile .dropdown.is-open {
  //   display:block;
  //   // max-height:initial;
  //   opacity:1;
  // }

  .user-profile .dropdown-trigger {
    display: table;
    cursor: pointer;
    > span {
      display: table-cell;
      vertical-align: middle;
      + span {
        padding-left: spToRem(12);
      }
    }
    .user-info {
      text-align: right;
      line-height: 1;
      div + div {
        color: $blue;
        font-size: spToRem(12);
      }
    }
    .arrow_down {
      > i.material-icons {
        font-size: spToRem(24);
      }
      padding-left: 0;
    }
  }
}

$max-title: 80rem; //$sidebar-max-width + 6.4rem;
// Breadcrumbs
#title {
  // display:table-row;
  display: inline-block;
  // height:$breadcrumb-height;
  line-height: 1;
  h1 {
    // margin:spToRem(-9) 0 0 0;
    margin: 0;
    overflow: hidden;
    max-width: calc(100vw - #{$max-title});
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 3.2rem;
    margin-top: -0.5rem;
    min-width: 260px;
    &:empty {
      height: 2.7rem;
      background: $darknavy;
      display: inline-block;
      width: 10vw;
      opacity: 0.2;
    }
  }
  > div {
    display: table-cell;
    padding: spToRem(24) spToRem(24) 0 spToRem(24);
    vertical-align: middle;
    + div {
      text-align: right;
    }
  }
}

.breadcrumbs {
  display: block;
  font-family: $font-family-heading;
  font-size: spToRem(14);
  @include customize() {
    color: getValue(breadcrumbs-text);
  }
  font-weight: 400;
  margin-top: spToRem(3);
  line-height: 1.3;
  &:empty {
    height: 1.9rem;
    background: $gray;
    display: block;
    width: 7vw;
    opacity: 0.2;
  }
  a {
    @include customize() {
      color: getValue(breadcrumbs-text);
    }
    &:hover {
      @include customize() {
        color: shade(getValue(breadcrumbs-text), 20%);
      }
    }
  }
  li {
    display: inline-block;
    letter-spacing: spToRem(0.3);
    // text-transform: capitalize;
    + li {
      &:before {
        content: "\e5c8";
        font-family: $font-material;
        display: inline-block;
        padding: 0 spToRem(4);
        font-size: spToRem(12);
        vertical-align: middle;
        opacity: 0.6;
      }
    }
  }
  .home {
    font-size: spToRem(14);
    vertical-align: -2px;
  }
}

.sticky {
  #header-wrapper {
    position: relative;
  }
  #container {
    position: relative;
  }
  #header-viewport-container {
    position: relative;
  }
  #title {
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }
  &.title-hidden.title-in-header,
  &:not(.title-in-header):not(.title-hidden) {
    #title {
      opacity: 1;
    }
  }
  &:not(.title-in-header) {
    &.title-hidden {
      #header {
        transform: translateY(-144px) !important;
      }
    }
    #header {
      transform: translateY(0);
    }
    // #header-viewport-container {
    //   text-align: right;
    //   position: sticky;
    //   left: 0;
    //   margin-left: -($sidebar-max-width);
    //   // width: calc(100vw - #{$sidebar-max-width});
    //   width: 100vw;
    // }
  }
  // &.remove-header-transition {
  //   #header {
  //     transition: none !important;
  //   }
  // }
  #header {
    transition: transform 0.3s ease-out;
    will-change: transform;
    display: block;
    // width: calc(100% - #{$sidebar-max-width});
    // width: calc(100vw + #{$sidebar-max-width});
    // left: -($sidebar-max-width);
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    height: unset;
    // box-shadow:0 1px 0 0 rgba(0,0,0,0), 0 1px 22px 0px rgba(0, 0, 0, 0);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0);
    z-index: 4;
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  }
  #header-holder {
    width: 100%;
    display: block;
    height: $header-height;
  }
  &.title-in-header {
    // #header-holder {
    //   width:calc(100% - #{$sidebar-max-width});
    // }
    #header {
      position: fixed;
      transform: translateY(0);
      z-index: 5;
      // width:100%;
      // box-shadow:0 1px 0 0 rgba(0,0,0,0.1), 0 1px 22px 0px rgba(0, 0, 0, 0.07);
      @include customize() {
        box-shadow: getValue(header-shadow-sticky);
      }
      top: 0;
      .head {
        position: absolute;
        position: sticky;
        // right:0;
        // @include customize() {
        //   background:getValue(header-bg);
        // }
        margin-right: -($scrollbar-width);
        pointer-events: auto;
        z-index: 4;
        right: 0;
        // &:before {
        //   content: " ";
        //   width: 80px;
        //   left: -80px;
        //   height: 100%;
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        //   @include customize() {
        //     background-image: linear-gradient(to right, transparent, getValue(header-bg));
        //   }
        // }
      }
    }
    #title {
      display: block;
      position: absolute;
      top: -1.2rem;
      z-index: 5;
      // margin-left: $sidebar-max-width;
      h1 {
        min-width: 180px;
      }
      // margin-left: $sidebar-max-width * 2;
    }
  }
  #header-placeholder {
    // display: table-header-group;
    // vertical-align: top;
    &:before {
      content: " ";
      display: block;
      // display: table-row;
      height: $header-height * 2;
    }
  }
}

// .sticky-old {
//   #header {

//     &:before {
//       content:" ";
//       display:block;
//       position:fixed;
//       top:0;
//       background: #ffffff;
//       width:100%;
//       height:$header-height;
//       z-index:1;
//     }
//   }
//   #header-holder {
//     background:transparent;
//     position:sticky;
//     top:0;
//     display:block;
//     left:0;
//     right:0;
//     height:$header-height;
//     z-index:2;
//   }
//   #title {
//     position:sticky;
//     top:-1.3rem;
//     z-index:0;
//     display:inline-block;
//     -webkit-backface-visibility: hidden;
//     -webkit-transform: translateZ(0) scale(1.0, 1.0);
//   }
//   .title-in-header {
//     #header-holder {
//       box-shadow:0 1px 0 0 rgba(0,0,0,0.1), 0 1px 22px 0px rgba(0, 0, 0, 0.07);
//       pointer-events: none;
//       > ul:last-child {
//         background:white;
//         pointer-events:auto;
//         &:before {
//           content: " ";
//           width: 80px;
//           left: -80px;
//           height: 100%;
//           position: absolute;
//           right: 0;
//           top: 0;
//           background-image: linear-gradient(to right, transparent, white);
//         }
//       }
//     }
//     #title {
//       z-index:1;
//       animation: fadeInFromNone .6s ease-in-out 0s 1 normal;
//       h1 {
//         pointer-events:none;
//         user-select:none;
//       }
//     }
//   }
// }

// max-width:300px;
// text-overflow:ellipsis;
// white-space:nowrap;
// overflow:hidden;

@include media-max(800px) {
  #header-holder span.user-info {
    display: none !important;
  }
}

@include safari-only() {
  .sticky:not(.title-in-header) #header-viewport-container {
    width: auto;
    right:0;
    max-width:100vw;
  }
}