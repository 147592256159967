.indicator-details-modal {
  .modal__close { z-index: 2; }
}

#indicator-details {
  height: 100%;
  display: flex;
  // align-items: flex-start;
  align-items: stretch;
  min-height: 500px;
  gap: 12px;
  .trigger-details__header {
    display: flex;
    // align-items: center;
    align-items: baseline;
    justify-content: space-between;
  }
  .trigger-details__advanced {
    header p {
      position: absolute;
      font-size: 1.2rem;
      margin-top: -2px;
    }
    position: absolute;
    z-index: 1;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transform: translate(-2.4rem, 0);
    will-change: transform;
    // transform-origin: top left;
    overflow: auto;
    &[data-position="bottom"] {
      overflow: initial;
      > div {
        margin-top: -24px;
        padding-top: 24px;
      }
    }
    > div {
      @include customize() {
        background-color: getValue(modal-bg);
      }
    }
    article {
      height: auto;
      + header {
        margin-top: 2.4rem;
      }
    }
    &__hide {
      $p: 16px;
      padding: $p 0;
      margin: -($p) 0;
      margin-right: 2px;
      opacity: 0.7;
      @include customize() {
        color: getValue(subtext);
      }
      transition: opacity 0.3s ease-out; // same as close btn
      &:hover {
        opacity: 1;
      }
    }
  }
  .form-row > *:first-child {
    width: 100px;
  }
  .indicator-details__column {
    width: 50%;
    position: relative;
    > * {
      transition: opacity .2s ease-in-out, transform .2s ease-out;
    }
    &[data-show-triggers="true"] {
      .indicator-graph { opacity: 0.14; pointer-events: none; }
      .trigger-details__advanced { opacity: 1; pointer-events: auto; transform: translate(0, 0); }
    }
  }
  &[data-type="10"] {
    .indicator-details__column {
      width: 100%;
    }
  }
  // .trigger-details__advanced table.table {
  //   tbody tr td {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //   }
  // }
  .trigger-details__advanced__cell {
    // padding: 0px 2px;
    padding: 6px 2px;
    div {
      display: flex;
      width: 100%;
      gap: 1px;
      > span {
        width: 50%;
        &:nth-child(1) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    &:last-child { padding-right: 1.2rem; }
  }
  th.trigger-details__advanced__cell {
    vertical-align: middle;
    span { text-align: center; }
  }
  // td.trigger-details__advanced__cell {
  //   &:last-child { padding-right: 1.2rem; }
  // }
}