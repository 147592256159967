// https://pixels.market/illustrations/chromablue

.svg {
  &__stroke--none { stroke-width: 0; }
  &__isolate { isolation: isolate; }
  &__fill--primary, &__children__fill--primary > * { fill: var(--product-primary); }
  &__fill--secondary, &__children__fill--secondary > * { fill: var(--product-secondary); }
}

svg {
  [data-stroke="0"],
  [data-children-stroke="0"] > *,
  &[data-all-stroke="0"] path, &[data-all-stroke="0"] circle, &[data-all-stroke="0"] rect, &[data-all-stroke="0"] ellipse { 
    stroke-width: 0;
  }
  [data-isolate=""], [data-isolate="true"] { isolation: isolate; }
}

// .svg__fill--primarydark { fill: orange; }

.cb {
  &__background {
    opacity: 0.18;
    isolation: isolate;
  }
  &__shirt1 {
    fill: #ffffff;
  }
  &__skin1 {
    fill: #f9c3b7;
  }
  // skin_original { fill: #ffb4a3; } 
  &__hair1 {
    fill: #231f20;
  }
}

svg[data-reversed="true"] {
  .svg {
    &__fill--primary, &__children__fill--primary > * { fill: #ffffff; }
  }
  .cb {
    &__hair1 { fill: #1a2234; }
  }
}
