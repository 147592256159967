.language-switcher {
  .dropdown-trigger {
    cursor: pointer;
  }
}

#holder .dropdown-container {
  &.right {
    > ul li.inception-dropdown-item {
      .dropdown {
        right: auto;
        left: -100%;
        margin-left: 0.8rem;
        margin-top: -0.8rem;
        li {
          white-space: normal;
        }
      }
    }
    .inception-dropdown {
      &__arrow {
        margin-left: auto;
        margin-right: -0.4rem;
        // float: right;
        i.material-icons {
          padding-right: 0;
          transform: rotate(90deg);
        }
      }
      &__label {
        display: flex;
        align-items: center;
      }
    }
  }
  // left todo
  > ul li.inception-dropdown-item {
    &:hover .dropdown {
      display: block !important;
    }
  }
  .dropdown__category {
    padding: 0.8rem 0.4rem 0;
    h3 {
      white-space: normal;
    }
  }
}