[class^="theme-"] .select.indicator-select {
  .select__value-container--is-multi .select__multi-value {
    max-width:30%;
  }
  .select__menu {
    width:auto;
    min-width:100%;
    right:0;
    padding:0;
  }
  .select__menu-list {
    padding: 0.8rem;
  }
  .select__option {
    white-space: nowrap;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .select__option--is-selected {
    background:rgba($blue, 0.2);
    color:$blue;
  }
  .select__multi-value__label {
    .checkbox>span:before, .checkbox input~span:after{
      display:none;
    }
    .checkbox, .checkbox.small {
      padding-left:0;
    }
  }
  .select__value-container--is-multi.select__value-container--has-value {
    padding: 0 0.8rem !important;
  }
  &.small {
    .select__value-container--is-multi.select__value-container--has-value {
      padding: 0 0.6rem !important;
    }
  }
  [class^="selectedIndicator"] {
    font-weight:bold;
  }
  .select__placeholder {
    // overwrites styled
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  // partly selected:
  .select__status--SOME {
    .checkbox input:checked~span {
      &:before {
        background: rgba($blue, 0.1);
        border-color: rgba($blue, 0.1);
      }
      &:after {
        border-color:$blue;
      }
    }
    .checkbox.small input:checked~span:after {
      border-width: 0px 8px 0px 0px;
      height: 2px;
      width: 2px;
      transform: translateY(-50%);
      top: 50%;
    }
  }
}