#footer {
  // background:gray;
  // height: 1px;
  margin-top: auto;
  font-size: spToRem(12);
  @include customize() {
    color: getValue(footer-color);
  }
  opacity: 0.7;
  transition: opacity 0.4s ease;
  &:hover {
    opacity: 1;
  }
  > * {
    text-align: right;
    padding: spToRem(21) spToRem(24);
    padding-top: 0;
  }
}

.copyrights__wrapper a { color: inherit; }