.ts-chart-header {
  display: table;
  width: 100%;
  > div {
    margin: -4px;
    > * {
      margin: 4px;
    }
  }
  > * {
    display: table-cell;
    vertical-align: top;
    &:last-child {
      width: 1px;
      white-space: nowrap;
      text-align: right;
    }
  }
  &-select, .react-datepicker-wrapper {
    vertical-align: top;
    width: auto;
    min-width: 130px;
  }
  .start-end-select {
    min-width: 160px;
  }
  .react-datepicker-wrapper {
    // width: 116px;
    width: 1px;
    min-width: 106px;
  }
  .react-datepicker__tab-loop {
    display: inline-block;
  }
  .react-daterange__button {
    justify-content: center;
    .l {
      position: absolute;
      display: none;
    }
    &.values-loading {
      pointer-events: none;
      cursor: default;
      .icon {
        opacity: 0.2;
      }
      &.last-clicked {
        .icon { visibility: hidden; }
        .l { display: inline-block; }
      }
    }
  }
}
.ts-chart-wrapper {
  position: relative;
  width: 100%;
  // margin: auto;
  // height: 418px;
}
