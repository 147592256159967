$close-button-help-modal-bg-color: #00b3ff;

.help-modal {
  .modal__close {
    z-index: 1;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 3px;
    transition: background 0.3s ease;
    &:hover {
      background: $close-button-help-modal-bg-color !important;
    }
  }
  .modal__close-icon {
    stroke: white;
    opacity: 1;
    &:hover {
      stroke: white;
    }
  }
  &.help-video-modal {
    background-color: transparent !important;
    border-radius: 0 !important;
  }
  .modal-content {
    padding: 0;
  }
  &.help-manual-modal {
    width: auto !important;
    .modal__close {
      // margin-top: 32px;
      // margin-right: 0;
      background: transparentize(black, 0.5);
    }
  }
  article {
    display: block;
  }
  .modal__special-icon {
    text-align: center;
    border: 1px solid transparent;
    margin-right: 28px;
    height: 32px;
    width: 32px;
    display: flex;
    i.material-icons {
      align-self: center;
      height: auto;
      font-size: 2.2rem;
      color: white;
    }
  }
}

.help {
  &__svg__container {
    svg {
      width: auto;
      height: 100%;
    }
    height: 17.7vw;
    min-height: 180px;
  }
  &-view {
    // .carousel-container {
    //   font-size: 0;
    //   min-height: $help-height-carousel;
    // }
    p.help__no-results {
      height: calc(var(--grid-thumbnail-height) + 50px);
      // line-height: $help-height-carousel;
      text-align: center;
      opacity: 0.7;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // h2 {
    //   color: #abafb7;
    //   font-size: 1.4rem;
    // }
    .arrow {
      height: 100%;
      width: 100%;
      background: rgba(245, 248, 250, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      &-left {
        i.material-icons {
          margin-left: -10px;
        }
      }
      &-right {
        i.material-icons {
          margin-right: -10px;
        }
      }
      > span {
        font-weight: 600;
        font-size: 1.3rem;
      }
      i.material-icons {
        font-size: 2.8rem;
      }
    }
    // .searchInput {
    //   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
    //   &:before {
    //     z-index: -1;
    //     box-shadow: 0 5px 15px rgba($navy, 0.2);
    //     content: " ";
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     opacity: 0;
    //     transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    //   }
    //   &:hover,
    //   &:focus-within {
    //     &:before {
    //       opacity: 1;
    //     }
    //   }
    //   input {
    //     font-size: 1.4rem;
    //     padding: 0 2.6rem;
    //     height: 6.4rem;
    //     line-height: 6.4rem;
    //     border-width: 0;
    //     &:hover,
    //     &:active {
    //       box-shadow: none;
    //     }
    //   }
    //   .icon {
    //     font-size: 2.4rem;
    //     color: $blue;
    //     right: 2.6rem !important;
    //   }
    // }
    .grid__play__icon {
      display: table-cell;
      font-size: 6.4rem;
      color: white;
      opacity: 0.5;
      transition: opacity 0.3s ease-out;
    }
    .grid__item {
      &:hover {
        .grid__play__icon {
          opacity: 1;
        }
      }
    }
    .grid__thumbnail {
      &[data-type="video"] {
        background-image: url(#{$ASSETS-PATH}/other/favicon-72.png); // TODO
      }
      &[data-type="manual"] {
        background-image: url(#{$ASSETS-PATH}/file-types/icon-acrobatFile@2x.png); // TODO
      }
    }
  }
  &__contact {
    @extend %footer-contact;
    li {
      font-size: 1.6rem;
    }
    i.material-icons {
      font-size: 1.8rem;
    }
    h6 {
      font-size: 1.8rem;
      letter-spacing: 0;
      margin-bottom: 0.6rem;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 4.8rem; // 2vw
      gap: 3vw;
      padding: 1.2rem;
      flex-wrap: wrap-reverse;
      .meta {
        flex: 1 1 0;
        max-width: fit-content;
      }
    }
  }
}