.overlay-scroller, .overlay-scroller-content { 
  // width: 300px;
  border: none 0px RED;
  overflow-x: scroll;
  overflow-y: hidden;
}
.overlay-scroller, .overlay-scroller-width-duplicate {
  height: $scrollbar-height;
}

// .overlay-scroller-width-duplicate, .overlay-scroller-width {
//   width: 1000px;
// }

.overlay-scroller-wrapper {
  position: relative;
  margin-bottom: $scrollbar-height + 8px;
}

.overlay-scroller {
  position: fixed;
  bottom: 0;
  z-index: 1;
  -ms-overflow-style: scrollbar;
}

.overlay-scroller-content {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    height: 0; /* For Chrome, Safari, and Opera */
    display: none;
  }
}


// .ie {
//   .overlay-scroller {
//     width: 100%;
//   }
  // .overlay-scroller-content {
  //   overflow-x: hidden;
  // }
  // .sticky {
  //   .left .scroller {
  //     position: fixed;
  //     top: ($header-height * 2) + 1.6rem;
  //   }
  //   &.title-hidden, &.title-in-header {
  //     .left .scroller {
  //       top: $header-height + 1.6rem;
  //       // position: fixed;
  //     }
  //   }
  // }
// }