@use "sass:math";
$product-map: (
  module-resilience: (primary: #65C0BB, secondary: #C2E3E2),
  module-nhs-region: (primary: #D80C3D, secondary: #EF797C),
  module-elective: (primary: #EAC618, secondary: #F4E5A6),
  module-vantage: (primary: #C3559A, secondary: #DFB1D2),
  module-waitless: (primary: #7BA0D4, secondary: #BED1E9)
);

$special-product-map: (
  module-covid: (primary: #C3559A),
  module-action: (primary: #9FC41F, secondary: #D2E19B)
);

@each $module, $colour-map in $product-map {
  #wrapper.#{$module} {
    --product-primary: #{map-get($colour-map, "primary")};
    --product-secondary: #{map-get($colour-map, "secondary")};
    // --product-primarydark: black;
  }
}

$medium-pattern-percentage: math.div($sidebar-med-width, $sidebar-max-width);
$min-pattern-percentage: math.div($sidebar-min-width, $sidebar-max-width);

@mixin generate-sidebar-accent($name, $m) {
  .#{$name} {
    #sidebar {
      a.active {
        svg [data-name="layer1"],
        svg [data-name="layer3"] {
          // stroke: map-get($m, "secondary");
          stroke: tint(map-get($m, "primary"), 10%); // sidebar-svg-stroke-1-active
        }
      }
      a.active {
        .invert {
          svg [data-name="layer2"] {
            // stroke: map-get($m, "secondary");
            stroke: tint(map-get($m, "primary"), 10%); // sidebar-svg-stroke-1-active
          }
        }
      }
    }
    [class^="theme-"] & {
      #sidebar #navigation > div > ul .sublist {
        li a.active {
          // color: map-get($m, "secondary");
          color: tint(map-get($m, "primary"), 25%); // sidebar-sublist-item-active
          box-shadow: -1px 0 0 map-get($m, "primary");// sidebar-sublist-border-active
        }
        a > span:before {
          background: map-get($m, "primary"); // sidebar-sublist-item-bullet
        }
      }
    }
  }
}

@mixin generate-header-accent($name, $m) {
  [class^="theme-"] .#{$name} {
    #header-holder .user-profile .dropdown-trigger {
      .user-info div + div {
        color: map-get($m, "primary");
      }
      .arrow_down {
        color: map-get($m, "primary");
      }
    }
    .user-emblem {
      color: white;
      background: map-get($m, "primary");
    }
  }
}


@mixin generate-sidebar-pattern-size($size, $left, $bottom) {
  background-position: left $left bottom $bottom;
  background-size: $size;
  // background-attachment: fixed;
  // @include media-max($breakpoint-alias-med-sidebar) {
  //   background-size: $medium-pattern-percentage * $size;
  //   background-position: left $left * $medium-pattern-percentage bottom $bottom * $medium-pattern-percentage;
  // }
  // @include media-max($breakpoint-alias-min-sidebar) {
  //   background-size: $min-pattern-percentage * $size;
  //   background-position: left $left * $min-pattern-percentage bottom $bottom * $min-pattern-percentage;
  // }
}

// Sidebar App Button:
@each $name, $colour-map in $product-map {
  .btn {
    @include generate-button-colour($name, map-get($colour-map, "primary"));
  }
  @include generate-sidebar-accent($name, $colour-map);
  @include generate-header-accent($name, $colour-map);
}


// Sidebar background accent
#navigation {
  background-repeat: no-repeat;
  > div {
    border-radius: 20%;
    @include customize() {
      background: rgba(getValue(sidebar-color-bg), 0.9);
    }
  }
  .module-elective & {
    // background-attachment: fixed;
    background-image: url(#{$ASSETS-PATH}/patterns/elective.svg);
    @include generate-sidebar-pattern-size(90rem, -33rem, -16rem);
  }
  .module-vantage & {
    // background-attachment: fixed;
    background-image: url(#{$ASSETS-PATH}/patterns/vantage.svg);
    @include generate-sidebar-pattern-size(60rem, -29rem, -20.2rem);
  }
  .module-resilience & {
    // background-attachment: fixed;
    background-image: url(#{$ASSETS-PATH}/patterns/resilience.svg);
    @include generate-sidebar-pattern-size(60rem, -19rem, -14.7rem);
  }
  .module-nhs-region & {
    // background-attachment: fixed;
    background-image: url(#{$ASSETS-PATH}/patterns/region.svg);
    @include generate-sidebar-pattern-size(60rem, -29rem, -20.2rem);
  }
  .module-waitless & {
    // background-attachment: fixed;
    background-image: url(#{$ASSETS-PATH}/patterns/waitless.svg);
    background-size: 100%;
    background-position: left 0 bottom 0;
    // @include generate-sidebar-pattern-size(100%, 0, 0);
  }
}

// covid highlight SPECIAL:
// $covid-module-colour:#C3559A;
$covid-module: map-get($special-product-map, "module-covid");
[class^="theme-"] #sidebar a[href^="/resilience/covid/"] {
  &.active {
    // color: $covid-module-colour !important;
    color: white !important;
    box-shadow: 0 0 0 1px map-get($covid-module, "primary");
    // background: transparentize($covid-module-colour, 0.5) !important;
    svg [data-name="layer1"],
    svg [data-name="layer3"] {
      // stroke: #2F3E60;
      // stroke: tint($covid-module-colour, 10%); // sidebar-svg-stroke-1-active
      stroke: map-get($covid-module, "primary");
    }
  }
}

// action highlight SPECIAL:
$action-module: map-get($special-product-map, "module-action");
// $action-module-colour:#9FC41F; // #A7C143
[class^="theme-"] ul.dropdown li.escalation-accent-dropdown-item {
  a {
    border: 1px dotted transparentize(map-get($action-module, "primary"), 0.4);
    border-left-width: 0;
    border-right-width: 0;
    color: map-get($action-module, "primary");
    &:hover {
      color: map-get($action-module, "primary");
      background: transparentize(map-get($action-module, "primary"), 0.85);
    }
  }
}
[class^="theme-"] #modal-escalation-actions {
  header {
    margin: -24px;
    padding: 24px;
    // margin-bottom: 0;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-image: url(#{$ASSETS-PATH}/patterns/action.svg);
    background-size: 40rem;
    background-position: -2.8rem -8.2rem;
    h4 {
      color: map-get($action-module, "primary");
      background: white;
      display: inline-block;
      padding: 4px 6px;
      margin: -4px -6px;
      border-radius: 5px;
    }
  }
  .tabs > .tab-list > .tab-list-item {
    &:hover {
      // box-shadow: inset 0 -3px 0 0 rgb(65 160 242 / 50%);
      box-shadow: inset 0 -3px 0 0 map-get($action-module, "secondary");
      color: map-get($action-module, "primary");
    }
    &.active {
      box-shadow: inset 0 -3px 0 0 map-get($action-module, "primary");
      color: map-get($action-module, "primary");
    }
  }
  .tabs-vertical > .tab-list .tab-list-item {
    // &:hover {}
    &:hover, &.active {
      color: map-get($action-module, "primary");
      // background: map-get($action-module, "secondary");
      background: transparentize(map-get($action-module, "primary"), 0.85);
    }
  }
  .tab-list-item .tag.bg-blue {
    background: map-get($action-module, "primary");
  }
}


body:not(.title-in-header) {
  &[data-powerbi-key="ESCALATION"] {
    #container {
      background-repeat: no-repeat;
      background-image: url(#{$ASSETS-PATH}/patterns/action.svg);
      background-size: 38rem;
      background-position: -7.6rem 3.4rem;
    }
    #header {
      #title {
        margin-left: 220px;
        h1 {
          color: map-get($action-module, "primary");
          // @include customize() {
          //   background: getValue(body-bg);
          // }
          padding: 0.6rem 0;
          margin-bottom: 0.2rem !important;
          margin-top: -1.1rem;
        }
      }
      // .breadcrumbs li {
      //   @include customize() {
      //     background: getValue(body-bg);
      //   }
      // }
    }
  }
}
