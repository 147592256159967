@use "sass:color";

$step-color: #e7edf5;

// ? Background darker for mfa screens
.login {
  &[data-label="VERIFY_REGISTER_MFA"], &[data-label="LOG_IN"] {
    .right {
      // background: #fbfbfb;
      @include customize() {
        background: getValue(body-bg);
      }
    }
  }
}

// ? sent code message
.mfa__sent {
  font-size: 1.3rem;
  border-radius: 3px;
  padding: 1px 6px;
  &--success {
    color: $green;
    background: color.change($green, $alpha: 0.10);
  }
}

// ? MFA steps
.mfa__step {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  &__side {
    flex-grow: 1;
    margin-top: -2px;
  }
  &__title {
    &.mfa__options__icon {
      height: min-content;
    }
    min-width: 48px;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: $step-color;
    font-weight: bold;
    flex-direction: column;
    border-radius: 4px;
    height: 20px;
    font-size: 12px;
    padding: 0 4px;
  }
  kbd > samp {
    white-space: nowrap;
  }
}

// ? OTP Input
.otp {
  &__wrapper {
    display: flex;
    // align-items: center;
    margin-top: 16px;
    flex-direction: column;
    .checkbox {
      font-size: 1.3rem;
    }
  }
  // &__dash {
  //   display: flex;
  //   width: 10px;
  //   justify-content: center;
  //   align-items: center;
  //   > div {
  //     width: 3px;
  //     height: 1px;
  //     border-radius: 50%;
  //     border: 1px solid gray;
  //   }
  // }
  &__slot {
    $slot-radius: 6px;
    &:first-child {
      border-top-left-radius: $slot-radius;
      border-bottom-left-radius: $slot-radius;
    }
    &:nth-last-child(2) {
      border-top-right-radius: $slot-radius;
      border-bottom-right-radius: $slot-radius;
    }
    position: relative;
    width: 60px;
    height: 60px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // @include transitionElement();
    transition: outline 0.1s ease-out, background-color 0.1s ease-out;
    outline-width: 1px;
    outline-style: solid;
    @include customize() {
      outline-color: getValue(input-border);
      // border-left-color: transparent;
      // &:first-child {
      //   border-left-color: getValue(input-border);
      // }
      background: getValue(input-bg);
      color: getValue(body-color);
    }
    &--active {
      @include customize() {
        background: getValue(input-bg-hover);
        outline-color: getValue(input-box-shadow);
        // box-shadow: 0 0 0 2px getValue(input-box-shadow);
      }
      outline-width: 3px;
      z-index: 1;
    }
  }
  &__container {
    container-type: inline-size;
    display: flex;
    align-items: center;
  }
  &__caret {
    position: absolute;
    pointer-events: none;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: caret-blink 1.25s ease-out infinite;
    > div {
      width: 1px;
      height: 2rem;
      @include customize() {
        background: getValue(body-color);
      }
    }
  }
}

@keyframes caret-blink {
  0%, 70%, 100% {
    opacity: 1;
  }
  20%, 50% {
    opacity: 0;
  }
}

// ? QR Code & QR Manual
.qr__code {
  margin: 16px 0 0;
  display: flex;
  align-items: center;
  // gap: 32px;
  &__image {
    * {
      height: 100%;
    }
  }
  &__accordion {
    display: grid;
    grid-template-rows: 0fr;
    grid-template-columns: 0fr;
    white-space: nowrap;
    transition: grid-template-rows 0.3s ease-out, grid-template-columns 0.3s ease-out;
    animation: qr-finish 0.3s ease-out;
    // animation-delay: 0.3s;
    animation-fill-mode: backwards;
    &[aria-hidden="false"] {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      animation-fill-mode: forwards;
      // white-space: normal;
    }
    > * {
      overflow: hidden;
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
    > * * {
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > * > *:nth-child(1) {
      margin-top: 8px;
    }
  }
  &__wrapper {
    display: inline-block;
    // margin-top: 1.6rem;
    margin-top: 0.8rem;
    margin-bottom: -0.8rem;
    &--info {
      // padding: 4px 8px 8px;
      padding: 8px;
      margin-left: -8px;
      margin-top: 1.6rem;
      transition: border-color 0.3s ease-out, background-color 0.3s ease-out;
      background-color: color.change($step-color, $alpha: 0);
      border: 1px solid color.change($step-color, $alpha: 0);
      border-radius: 3px;
      &[data-expanded="true"] {
        background-color: color.change($step-color, $alpha: 0.2);
        border-color: $step-color;
      }
    }
  }
  &__manual {
    display: flex;
    flex-direction: column;
    gap: 8px;
    label {
      font-weight: bold;
      margin-bottom: 4px;
    }
    code {
      background: $step-color;
      display: inline-block;
      width: 100%;
      word-break: break-word;
    }
  }
}

@keyframes qr-finish {
  0% {
    white-space: nowrap;
  }
  92%, 100% {
    white-space: normal;
  }
}

// ? Options in MFA setup
a.mfa__options__item {
  text-decoration: none;
}
.mfa__options {
  display: flex;
  flex-direction: column;
  gap: 18px;
  &__arrow {
    font-size: 28px;
    transform: translateX(-2px);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0.36;
    @include customize() {
      color: getValue(text);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 2.6rem;
    border: 1px solid $step-color;
    border-radius: 8px;
    &:hover {
      background: rgba($lightgray, 0.16);
      .mfa__options__arrow {
        transform: translateX(4px);
        opacity: 1;
      }
    }
    > * {
      &:last-child {
        margin-left: auto;
      }
    }
  }
  &__icon {
    width: 48px;
    padding: 1rem;
    background: $step-color;
    border-radius: 8px;
    svg {
      pointer-events: none;
    }
  }
  &__title {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}

// ? Sample Authenticator Apps modal
.authenticator__popover {
  + hr {
    margin: 4.8rem 0;
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
  footer {
    margin-top: 16px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    gap: 12px;
    align-items: center;
    h2 { margin-bottom: 0; }
    img {
      width: 42px;
      height: 42px;
      border-radius: 20%;
      border: 1px solid $step-color;
    }
  }
  .badges {
    justify-content: flex-end;
    padding-bottom: 0 !important; 
    margin-left: auto;
  }
}

