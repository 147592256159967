@use "sass:math";
// $sidebar-padding: spToRem(12);
$sidebar-padding: 12px;
// $select-bg-color:transparentize(black, 0.80);

.platform-svg {
  display: flex;
  // align-items: center;
  align-items: initial; // safari fix
  justify-content: center;
  width: 32px;
  height: 32px; // ie11
}

.sidebar-dim {
  #sidebar:before {
    position: absolute;
    content: " ";
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .modal-cover {
    z-index: 5;
  }
}

#navigation {
  .ico {
    // width:1px;
    // padding-right:spToRem(12);
    width: spToRem(20);
    flex-shrink: 0;
    ~ span {
      @include sidebarCollapsed();
      padding-left: spToRem(12);
    }
  }
  .spacer {
    white-space: nowrap;
    position: relative;
    span  {
      @include sidebarCollapsed();
    }
  }
  .category-collapsed {
    // display: none;
    pointer-events: none;
    height: 2px;
    width: 18px;
    @include customize() {
      background: getValue(sidebar-family-color);
    }
    transition: opacity 0.3s ease, transform 0.3s ease;
    margin-left: 0.7rem;
    bottom: 12px;
    position: absolute;
    opacity: 0;
    transform: translateX(16px);
    .is-collapsed:not(:hover) & {
      // display: inline-block;
      opacity: 1;
      transform: translateX(0);
    }
  }
}

#sidebar {
  position: fixed; //backup
  // position: relative; //backup
  // position: sticky;
  // float: left;
  top: 0;
  bottom: 0;
  height: 100vh !important;
  z-index: 6;
  #navigation {
    height: 100%;
  }
  a:hover {
    text-decoration: none;
  }
  svg {
    pointer-events: none; // prevents the tooltips from showing up
    // width:spToRem(20);
    // width:100%;
    vertical-align: middle;
    // width:12.8%;
    // height:auto;
    [data-name] {
      stroke-width: 4;
      transition: stroke 0.4s ease-in-out;
    }
  }
  // width: $sidebar-max-width;
  @include customize() {
    background: getValue(sidebar-color-bg);
    color: getValue(sidebar-color);
  }
  font-family: $font-family-heading;
  li {
    user-select: none;
  }
  // display:table;
  // height:100%;
  // > div {
    // display: table;
    // width: 100%;
    // height: 100%;
    // > div {
      // display:table-row-group;
      // width:100%;
    // }
  // }
  header,
  footer {
    // display: table-row;
    // padding:$sidebar-padding;
    // width:100%;
    // height:100%;
    > div {
      // display: table-cell;
      padding: $sidebar-padding 12px;
    }
  }
  // #navigation {
  //   display: table-row;
  // }
  // header {
  // }
  #navigation > div > ul {
    overflow-x: hidden;
    overflow-y: auto; //backup
    overflow-y: overlay;
    padding: $sidebar-padding;
    scrollbar-gutter: stable;
    padding-right: 4px;
    li > a {
      transition: background-color 0.3s cubic-bezier(0, 0, 0, 1),
        color 0.3s ease;
    }
    font-weight: 400;
    a.active {
      @include customize() {
        background-color: getValue(sidebar-color-active-bg);
      }
      border-radius: 4px;
    }
    .sublist {
      // display:none;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
      width: 100%;
      @include customize() {
        background-color: getValue(sidebar-color-active-bg);
      }
      @include borderRadius(0, 0, 4px, 4px);
      // border-radius:4px;
      // background:transparentize(white, 0.99);
      padding: 0 spToRem(21);
      padding-right: spToRem(8);
      font-size: spToRem(13);
      li {
        @include customize() {
          border-left: 1px solid getValue(sidebar-sublist-border-inactive);
        }
        // padding-top:spToRem(4);
        + li {
          padding-top: spToRem(4);
        }
        &:nth-child(1) {
          margin-top: spToRem(12);
        }
        &:last-child {
          margin-bottom: spToRem(12);
        }
        a.active {
          @include customize() {
            color: getValue(sidebar-sublist-item-active);
            background: getValue(sidebar-sublist-item-active-bg);
            box-shadow: -1px 0 0 getValue(sidebar-sublist-border-active);
          }
          > span:before {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
      a {
        display: flex;
        width: 100%;
        @include customize() {
          color: getValue(sidebar-sublist-item);
        }
        min-height: 32px;
        // border-radius:$el-radius;
        border-radius: 0;
        @include borderRadius(0, 4px, 4px, 0);
        // border-left: 6px solid transparent;
        padding: spToRem(10) spToRem(18); //12-6
        transition: all 0.3s ease-out;
        // > * {
        //   display: table-cell;
        //   vertical-align: middle;
        // }
        > span {
          position: relative;
          &:before {
            content: " ";
            // width:2px;
            // height:2px;
            // border:2px solid $whiteblue;
            @include customize() {
              background: getValue(sidebar-sublist-item-bullet);
            }
            border-radius: 4px;
            display: inline-block;
            // margin-right:4px;
            // vertical-align:1px;
            width: 7px;
            height: 7px;
            opacity: 0;
            position: absolute;
            margin-top: spToRem(5);
            left: spToRem(-22);
            transition: all 0.3s ease;
            transform: scale(0);
          }
        }
        &:hover {
          @include customize() {
            color: getValue(sidebar-sublist-item-hover);
          }
        }
      }
    }
    > li {
      &.spacer {
        padding: spToRem(12) 0 spToRem(4) spToRem(6);
        @include customize() {
          color: getValue(sidebar-family-color);
        }
        font-size: spToRem(11);
        letter-spacing: spToRem(0.4);
        text-transform: uppercase;
        font-weight: 500;
        &:first-child {
          padding-top: 0;
        }
      }
      i.caret {
        // display: none;
        visibility: hidden;
        // width: 1px;
        transition: transform 0.3s ease-out;
      }
      &.is-dropdown {
        i.caret {
          // display: block;
          visibility: visible;
        }
        > a {
          border-radius: 0;
          @include borderRadius(4px, 4px, 0, 0);
        }
      }
      > a {
        height: 36px;
        // display: table;
        align-items: center;
        display: flex;
        width: 100%;
        padding-left: spToRem(12);
        padding-right: spToRem(6);
        @include customize() {
          color: getValue(sidebar-item);
        }
        // background:transparentize(black, 0.80);
        border-radius: 4px;
        &:hover {
          @include customize() {
            color: getValue(sidebar-item-hover);
          }
        }
        // > * {
        //   display: table-cell;
        //   vertical-align: middle;
        // }
      }
      > .active {
        &a {
          @include customize() {
            color: getValue(sidebar-item-active);
          }
          // background:transparentize(white, 0.78);
        }
        i.caret {
          transform: rotate(180deg);
        }
        + ul.sublist {
          display: block;
          // max-height: 3000px;
          overflow-x: hidden;
          // overflow-y: auto;
          transition: max-height 1s ease-in-out;
          max-height: 400px;
          max-height: 40vh;
          overflow-y: hidden;
          animation: animationOverlayY 0ms ease-out 1s 1 normal forwards;
          scrollbar-gutter: stable;
        }
      }
      + li {
        margin-top: spToRem(4);
      }
    }
  }

  footer {
    // height: spToRem(58);
    @include customize() {
      background-color: getValue(sidebar-footer-bg);
    }
    font-weight: 500;
    > div {
      padding: 0 spToRem(24);
      height: spToRem(58);
    }
    ul {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
      // table-layout: fixed;
      text-align: center;
      li {
        flex: 1 1 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: " ";
          // width: 4px;
          // height: 4px;
          // border-radius: 50%;
          width: 6px;
          height: 2px;
          @include customize() {
            background: getValue(sidebar-svg-stroke-2);
          }
          display: none;
          opacity: 0;
        }
        // display: table-cell;
        // vertical-align: middle;
        // line-height:1.8;
      }
    }
    // svg {
    //   width:spToRem(20);
    // }
    .ico {
      width: spToRem(20);
      margin: spToRem(3) auto spToRem(4) auto;
    }
    a {
      @include customize() {
        color: getValue(sidebar-footer-item);
      }
      font-size: spToRem(11);
      display: inline-block;
      width: 100%;
      border-radius: 4px;
      background-color: transparent;
      padding: 4px;
    }
    .active a {
      @include customize() {
        background-color: getValue(sidebar-footer-item-active-bg);
      }
    }
  }
}

#sidebar.is-collapsed:not(:hover) .footer {
  li {
    &:after {
      display: block;
      opacity: 1;
    }
    a {
      display: none;
    }
  }
  > div {
    padding: 0 spToRem(12);
  }
  // a span {
  //   display: none;
  // }
}

#product-select {
  height: 100%;
  // width: 100%;
  width: auto;
  min-width: 100%;
  font-family: $font-family-heading; //?
  font-weight: 500;
  font-size: spToRem(18);
  // display: table;
  // border-radius:4px;
  padding: 0 2px 0 8px;
  box-shadow: none;
  display: flex;
  align-items: center;
  // > * {
  //   display: table-cell;
  //   vertical-align: middle;
  //   &:nth-child(1),
  //   &:last-child {
  //     width: 1px;
  //     vertical-align: middle;
  //   }
  // }
  > div ~ * {
    @include sidebarCollapsed();
  }
  span {
    width: 100%;
    text-align: left;
    padding-left: spToRem(6);
  }
  i.material-icons {
    font-size: spToRem(26);
    // vertical-align: middle;
  }
  i.caret {
    font-size: spToRem(24);
    opacity: 0.7;
    // transition: all .3s ease;
    transition: opacity 0.3s ease-out;
  }
  &:hover {
    i.caret {
      opacity: 0.9;
      // transform: scale(1.1);
    }
  }
}

#sidebar {
  svg {
    [data-name="layer1"],
    [data-name="layer3"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-1);
      }
    }
    [data-name="layer2"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-2);
      }
    }
  }
  a:hover {
    svg [data-name="layer1"],
    svg [data-name="layer3"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-hover);
      }
    }
    svg [data-name="layer2"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-hover);
      }
    }
  }
  a.active {
    svg [data-name="layer1"],
    svg [data-name="layer3"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-1-active);
      }
    }
    svg [data-name="layer2"] {
      @include customize() {
        stroke: getValue(sidebar-svg-stroke-2-active);
      }
    }
  }
  .invert {
    svg {
      [data-name="layer1"],
      [data-name="layer3"] {
        @include customize() {
          stroke: getValue(sidebar-svg-stroke-2);
        }
      }
      [data-name="layer2"] {
        @include customize() {
          stroke: getValue(sidebar-svg-stroke-1);
        }
      }
    }
  }
  a.active {
    .invert {
      svg [data-name="layer1"],
      svg [data-name="layer3"] {
        @include customize() {
          stroke: getValue(sidebar-svg-stroke-2-active);
        }
      }
      svg [data-name="layer2"] {
        @include customize() {
          stroke: getValue(sidebar-svg-stroke-1-active);
        }
      }
    }
  }
}

#navigation-content::-webkit-scrollbar {
  width: 10px;
}

#sidebar .sublist::-webkit-scrollbar {
  width: 14px;
}

#sidebar .sublist::-webkit-scrollbar, #navigation-content::-webkit-scrollbar {
  &-track {
    background-color: hsla(0, 0%, 100%, 0.10);
    // background-color: hsla(0, 0%, 0%, 0.22);
  }
  &-thumb {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: hsla(0, 0%, 100%, 0.24);
    // background-color: hsla(0, 0%, 0%, 0.6);
  }
}

@-moz-document url-prefix() {
  /* CSS styles to be applied only in Firefox */
  #navigation ul {
    scrollbar-color: hsla(0, 0%, 0%, 0.4) hsla(0, 0%, 0%, 0.05); //firefox scrollbar
  }
}

// #navigation ul::-webkit-scrollbar {
//   width: 12px;
//   background-color: transparent;
//   opacity: 0.6;
//   &-track {
//     background-color: hsla(0, 0%, 0%, 0.1);
//   }
//   &-thumb {
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//     background-color: hsla(0, 0%, 0%, 0.3);
//   }
// }

$max-product-btn-height: 4.8rem;
$max-products-height: ($numberOfApps * 5.4rem) + 4.8rem + 2rem;
#product-select-holder {
  display: flex;
  position: relative;
  ul.product-dropdown {
    top: $sidebar-padding;
    left: $sidebar-padding;
    // right: $sidebar-padding;
    @include customize() {
      color: getValue(product-dropdown-text);
    }
    border: 1px solid white;
    padding: 0;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    box-shadow: 0 4px 20px 4px rgba(0, 0, 0, 0.53),
      0 4px 80px -8px rgba(0, 0, 0, 0.16);
    &.is-open {
      animation: maxHeightFromNone 0.3s ease-in-out 0s 1 forwards;
      // max-height:$max-product-btn-height !important;
      // max-height:999px;
    }
    .header {
      height: $max-product-btn-height;
      font-size: 1.4rem;
      font-weight: 500;
      .back {
        font-size: 1.4rem;
        padding-right: 0 !important;
        width: 1px;
      }
    }
    .product-row {
      display: table;
      width: 100%;
      height: 100%;
      padding: 0.6rem 1.6rem;
      > * {
        display: table-cell;
        vertical-align: middle;
      }
      .info-icon {
        display: none;
      }
      &.is-disabled {
        background-color: #f5f5f5;
        transition: background-color 0.2s ease-out;
        cursor: help;
        h6 {
          opacity: 0.6;
        }
        .meta {
          opacity: 0.5;
        }
        // .icon {
        //   opacity: 0.3;
        //   [fill="#bfdef8"],
        //   [fill="#BFDEF8"] {
        //     fill: transparentize($navy, 0.7) !important;
        //   }
        //   [fill="#2a92e9"],
        //   [fill="#2A92E9"] {
        //     fill: $navy !important;
        //   }
        // }
        .info-icon {
          width: 1px;
          color: $blue;
          display: table-cell;
          cursor: pointer;
          i.material-icons {
            padding-right: 0;
            margin-right: -0.8rem;
          }
        }
        &:hover {
          background-color: #f9f9f9;
          // opacity: 0.7;
        }
      }
    }
    li > .subapps {
      &:empty {
        display: none;
      }
      display: block;
      h6 {
        margin-bottom: 0;
      }
      .product-row {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        position: relative;
        padding-left: 4.4rem;
        &:before {
          content: " ";
          display: block;
          position: absolute;
          @include customize() {
            border-bottom: 1px solid getValue(border-gray);
            border-left: 1px solid getValue(border-gray);
          }
          left: 2.4rem;
          width: 1.6rem;
          height: 50%;
          top: 0;
        }
      }
      li + li .product-row:before {
        top: -50%;
        height: 100%;
      }
      border-top: 1px solid transparent;
      border-color: $lightgray;
      // @include customize() {
      //   border-color: getValue(border-gray);
      // }
    }
    li {
      + li {
        margin-top: 0;
        border-top: 1px solid transparent;
        border-color: $lightgray;
        // @include customize() {
        //   border-color: getValue(border-gray);
        // }
      }
    }
    .info {
      padding-left: 1.6rem;
    }
    h6 {
      margin-bottom: 2px;
      font-size: 1.2rem;
      @include customize() {
        color: getValue(product-dropdown-text);
      }
    }
    .meta {
      font-size: 1rem;
      @include customize() {
        color: getValue(product-dropdown-meta);
      }
      font-weight: 400;
    }
  }
  .icon {
    // width: 4rem;
    width: 5rem;
    min-width: 5rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.modal.disabled-product-modal {
  overflow-x: hidden;
  &:before {
    content: " ";
    background: $blue;
    opacity: 0.07;
    height: 55%;
    display: block;
    position: absolute;
    /* z-index: 1222; */
    width: 130%;
    z-index: -1;
    transform: rotate(22deg);
    transform-origin: 0% 50%;
    top: -270px;
  }
  h4 {
    font-size: 2.4rem;
  }
  p {
    text-align: center;
  }
  .pic {
    border-radius: 5px;
    overflow: hidden;
    font-size: 0;
    margin-bottom: 4.8rem;
    img {
      max-width: 100%;
      height: auto;
      transform: scale(1);
      // transform-origin: left top;
      transition: 0.2s ease-in-out;
      will-change: transform;
      &:hover {
        // transform: scale(1.2);
      }
    }
    @include popover-shadow();
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }
  .platforms {
    text-align: center;
    padding-top: 2.4rem;
    .platform {
      display: inline-block;
      vertical-align: middle;
      + .platform {
        margin-left: 0.8rem;
      }
    }
    i.material-icons,
    svg > path {
      @include customize() {
        color: getValue(label);
      }
      font-size: 2rem;
      opacity: 0.24;
      transition: opacity 0.3s ease-out;
      &:hover {
        opacity: 0.5;
      }
    }
    svg {
      vertical-align: top;
      pointer-events: none; // prevents the tooltips from showing up
      > path {
        @include customize() {
          fill: getValue(label);
        }
      }
    }
  }
}

#sidebar .inline-error {
  h1, p, i {
    @include customize() {
      color: getValue(box-bg);
    }
  }
}

#sidebar {
  .caret-collapsed {
    width: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &.is-collapsed:not(:hover) .caret-collapsed {
    opacity: 1;
    // width: auto;
  }
}

#sidebar #navigation {
  span + i.caret {
    margin-left: auto;
  }
}

#sidebar.is-collapsed:not(:hover) {
  #navigation > div > ul {
    > li {
      .caret-collapsed i.caret {
        transform: scale(0.5) translate(-50%, 0) rotate(0);
      }
      .active {
        .caret-collapsed i.caret {
          transform: scale(0.5) translate(-50%, 0) rotate(180deg);
        }
      }
      // position: relative;
      > a.active + ul.sublist {
        // max-height: 0;
        display: none;
      }
      &:hover > a.active + ul.sublist {
        display: block;
      }
    }
    ul.sublist {
      position: absolute;
      width: $sidebar-max-width;
      // @include customize() {
      //   background: getValue(sidebar-color-bg);
      // }
      background: #1a2233 !important; // ! TODO
      z-index: 8;
    }
  }
}

.new-tag {
  font-weight: bold;
  font-size: 12px;
  color: lighten($green, 20%);
  background: rgba($green, 0.1);
  border: 1px solid rgba($green, 0.23);
  padding: 0 4px !important;
  border-radius: 2px;
  white-space: nowrap;
  margin-left: 8px;
  display: inline-block;
}


#sidebar #navigation > div {
  .is-dropdown > a > * {
    pointer-events: none;
  }
  > ul .sublist {
    li.sublist__search {
      $top: 0;
      margin: $top 0 1.2rem;
      top: $top;
      position: sticky;
      z-index: 1;
      + li {
        padding-top: 0;
      }
    }
    .sublist__search {
      border-radius: 2px;
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      border-style: solid;
      transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      @include customize() {
        border-color: transparentize(getValue(sidebar-color), 0.5);
        $x: shade(getValue(sidebar-color-bg), 20%);
        background-color: tint($x, 2%);
      }
      // background-color: hsla(0deg, 0%, 100%, 0.02);
      &:hover, &:focus-within {
        @include customize() {
          border-color: transparentize(getValue(sidebar-color), 0.3);
          $x: shade(getValue(sidebar-color-bg), 20%);
          background-color: tint($x, 6%);
        }
        // background-color: hsla(0deg, 0%, 100%, 0.06);
      }
      input, button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        outline: inherit;
      }
      button {
        cursor: pointer;
      }
      &__icon {
        $fz: 18px;
        font-size: $fz;
        padding: 0 math.div(30px - $fz, 2); 
      }
      &__input {
        width: 100%;
        @include customize() {
          color: getValue(sidebar-sublist-item-hover);
        }
        &::placeholder {
          opacity: 0.6;
          @include customize() {
            color: getValue(sidebar-sublist-item);
          }
        }
      }
      &__clear {
        font-size: 0;
        opacity: 0;
        height: 100%;
        transition: opacity 0.2s ease-in-out;
        // pointer-events: none;
        display: none;
        &[data-visible="true"] {
          // pointer-events: auto;
          display: block;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
        i {
          font-size: 16px;
          padding: 0 7px;
        }
      }
    }
  }
}

// .ie {
//   .modal.disabled-product-modal .pic img {
//     width: 100%;
//   }
// }

@media (max-width: 1280px) {
  .modal.disabled-product-modal {
    margin-left: $sidebar-max-width * 2 + 12px;
    left: 0;
  }
}

@keyframes maxHeightFromNone {
  0% {
    max-height: $max-product-btn-height;
  }
  1% {
    max-height: $max-product-btn-height;
  }
  100% {
    max-height: $max-products-height;
  }
}

@import "_sidebar-loader.scss";

// html:not(.ie) {
//   #sidebar #navigation > div > ul > li > .active + ul.sublist {
//     max-height: 400px;
//     max-height: 40vh;
//     overflow-y: hidden; //to be overwritten
//     animation: animationOverlayY 0ms ease-out 1s 1 normal forwards;
//   }
// }

// .ie {
//   #sidebar #navigation > div > ul > li > .active + ul.sublist {
//     overflow-y: auto;
//     animation: none !important;
//   }
// }

@supports (-ms-ime-align: auto) {
  // Edge
  #sidebar #navigation > div > ul > li > .active + ul.sublist {
    overflow-y: auto;
    overflow-y: overlay;
    animation: none !important;
  }
  #sidebar {
    position: fixed !important;
  }
}


@include safari-only() {
  #sidebar #navigation > div > ul > li > .active + ul.sublist {
    overflow-y: auto;
    overflow-y: overlay;
    animation: none !important;
  } 
  #sidebar {
    position: fixed !important;
  }
}