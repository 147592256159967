@use "sass:math";
$rect-loader-size: 5rem;
$rect-animation-duration: 1.5s;
$rect-loader-colour: white;
 

.rectangle-bounce {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: $rect-loader-size;
  height: $rect-loader-size;
  // transition: all 300ms ease-in-out 0s;
  z-index: 1;
 
  .rect-1,
  .rect-2,
  .rect-3,
  .rect-4,
  .rect-5 {
    width: 12%; // probably 6px
    height: 100%;
    background-color: $rect-loader-colour;
    animation: rectangle-bounce $rect-animation-duration infinite ease-in-out;
  }
 
  .rect-2 {
    animation-delay: -$rect-animation-duration + .1;
  }
  .rect-3 {
    animation-delay: -$rect-animation-duration + .2;
  }
  .rect-4 {
    animation-delay: -$rect-animation-duration + .3;
  }
  .rect-5 {
    animation-delay: -$rect-animation-duration + .4;
  }
} 
 
@keyframes rectangle-bounce {
  0%, 40%, 100% {
    transform:scaleY(0.4);
  }
  20% {
    transform:scaleY(1);
  }
}

// $rect-loader-size-small: 25px;
$rect-cached-scale: 0.4;
$inline-error-size: 40px;
$header-button-height: 48px; // $header-height - ($sidebar-padding * 2rem);

.rectangle-bounce {
  margin: 8vh auto 0 !important;
  transform-origin: center right;
  // transform-origin: top right;
  &.cached {
    position: absolute;
    top: 1.2rem;
    right: 48px;
    // width: $rect-loader-size-small;
    // height: $rect-loader-size-small;
    transform: scale($rect-cached-scale);
    margin: 0 !important;
    .is-collapsed:not(:hover) & {
      right: 26px;
      top: -1.3rem;
    }
  }
}

.sidebar-error {
  text-align: center;
  margin-top: -3.2rem !important;
  padding-top: 8vh;
  // pointer-events: none;
  transform-origin: center right;
  &.cached {
    position: absolute;
    top: math.div($header-button-height - ($inline-error-size * $rect-cached-scale), 2);
    right: 4.8rem + 0.2rem;
    margin-top: 0 !important;
    padding-top: 0;
    transform: scale($rect-cached-scale);
    .inline-error {
      h1, p {display: none;}
      i.material-icons {
        margin-bottom: 0;
      }
    }
  }
}
