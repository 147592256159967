// @use "sass:math";
@use "sass:color";

$tableau-icon-svg: '<svg xmlns="http://www.w3.org/2000/svg" height="60.3" width="59.5" viewBox="0 0 60.3 59.5"><path d="M28.5 40.2h3.3v-9h8.3V28h-8.3v-9h-3.3v9h-8.2v3.2h8.2z" fill="#e8762d"></path><path d="M13.2 53.2H16v-8h7.4v-2.5H16v-8.1h-2.8v8.1H5.8v2.5h7.4z" fill="#c72037"></path><path d="M44.3 24.3h2.8v-8h7.5v-2.4h-7.5V5.8h-2.8v8.1h-7.4v2.4h7.4z" fill="#5b879b"></path><path d="M29 59.5h2.4v-5.7h5.1v-2.1h-5.1V46H29v5.7h-5v2.1h5z" fill="#5c6692"></path><path d="M13.3 24.3h2.6v-8.1h7.5v-2.3h-7.5V5.8h-2.6v8.1H5.8v2.3h7.5z" fill="#eb9129"></path><path d="M52.8 36.3h2.4v-5.6h5.1v-2.2h-5.1v-5.6h-2.4v5.6h-5v2.2h5z" fill="#5c6692"></path><path clip-rule="evenodd" d="M44.3 53.2h2.8v-8h7.5v-2.5h-7.5v-8.1h-2.8v8.1h-7.4v2.5h7.4z" fill="#1f457e" fill-rule="evenodd"></path><path d="M36.1 7.2V5.5h-5V0h-1.8v5.5h-5v1.7h5v5.5h1.8V7.2zM5 35.9h1.8v-5.5h5v-1.7h-5v-5.4H5v5.4H0v1.8l5-.1z" fill="#7199a6"></path></svg>';

$tableau-bg-colour: #efefef;

#tableau-report {
  height: 100%;
  padding: 0px;
  // padding-bottom: 0;
  > div {
    height: 100%;
  }
  .tableau__header {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    margin-top: 4px;
    margin-right: 4px;
    .group {
      background: white;
      .btn.small > i {
        padding-right: 0;
      }
    }
  }
  .tableau__holder {
    background: $tableau-bg-colour;
    &.expanded {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      min-height: 100vh;
      overflow: auto;
      iframe {
        min-height: 100%;
      }
    }
    position: relative;
    > div {
      height: 100%;
    }
  }
  iframe {
    min-width: 100%;
    visibility: visible !important;
  }
  .tableau-loader-holder {
    position: absolute;
    top: 132px;
    left: 0;
    right: 0;
    height: auto;
    pointer-events: none;
    .l.tableau-custom {
      background: $tableau-bg-colour;
      // $loader-size: 72px;
      // $loader-colour: #616570;
      // // opacity: 0.1;
      // color: $loader-colour;
      // // border-left-color: $loader-colour;
      // // border-top-color: $loader-colour;
      // // animation-play-state: paused;
      // border-width: 4px;
      // width: $loader-size;
      // height: $loader-size;
      // margin: math.div($loader-size, -2) math.div($loader-size, -2) math.div($loader-size, 2);
    }
  }
  .third-party-cookie-notice {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    // height: 32px;
    padding: 16px 0;
    align-items: center;
    justify-content: center;
    // text-align: center;
    flex-direction: column;
    // background: $red; // $rt-color-error
    border: 2px solid transparent;
    border-radius: 4px;
    margin: 24px;
    @include customize() {
      border-color: getValue(input-invalid);
      background: mix(white, getValue(input-invalid), 90%);
    }
    p {
      font-weight: bold;
      @include customize() {
        color: getValue(follower-text);
      }
      + p {
        margin-top: 0.8rem;
      }
    }
  }
}

#tableau-repository {
  height: 100%;
  display: flex;
  flex-direction: column;
  .grid__container ~ footer {
    margin-top: auto;
    padding-top: 2.4rem;
  }
  .table {
    li.cell.name-holder {
      padding: 0;
      .report__name {
        font-weight: bold;
        padding: 1rem 1.2rem;
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  }
}
// ! to refactor
.table.grid-table {
  display: grid;
  border-collapse: collapse;
  // min-width: 100%;
  .thead, .tbody, .row {
    display: contents;
  }
  .th, 
  .cell {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    // white-space: nowrap;
  }
  &.shadow-hover-effect {
    .row { 
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
    }
  }
}



// $grid-view-bg: #f5f8fa;
$grid-description-line-height: 1.8em;

.tableau__grid__container {
  .grid {
    &__item {
      &:hover, &:focus-within {
        z-index: 1;
        // .grid__content {
          // transform: scale(1.02);
        // }
        .grid__description__full { grid-template-rows: 1fr; opacity: 1; }
        .grid__description[data-possible-expansion="true"] { line-clamp: 3; -webkit-line-clamp: 3; }
      }
    }
    &__thumbnail {
      &[data-thumbnail="false"] {
        background-image: svg-url($tableau-icon-svg);
      }
    }
  }
  .p {
    line-height: $grid-description-line-height;
  }
  .grid__description, .grid__description__full {
    @include customize() {
      background-color: getValue(body-bg);
    }
  }
  .grid__description {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: $grid-description-line-height * 2;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: flex;
    display: -webkit-box;
    position: relative;
    z-index: 1;
  }
  .grid__description__full {
    position: absolute;
    top: 0;
    margin-top: 0;
    opacity: 0;
    // transition: background-color 0.3s ease;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease 0.5s;
    // width: 100%;
    // background: linear-gradient(180deg, $grid-view-bg 0%, $grid-view-bg $grid-description-line-height * 3 + 0.6em, shade($grid-view-bg, 8%) 100%);
    // padding: 0 12px;
    // margin: 0 -12px;
    @include customize() {
      border-bottom: 16px getValue(body-bg) solid;
    }
    > * {
      grid-row: 1 / span 2;
      overflow: hidden;
      padding-bottom: 0.6em; // less than line height
    }
  }
}