svg g {
  .borders line,
  .borders circle,
  .borders .innerArcs > *,
  .outerArcs + .data > path {
    @include customize() {
      stroke: getValue(table-border-color);
    }
  }
  .inner_labels text > * {
    @include customize() {
      fill: getValue(text);
    }
  }
}

$svg-inner_labels: 14px;
$svg-escalation_icons: 24px;
$svg-outerArcsTextPath: 12px;

@function scaleWheelText($scale-percentage, $text-size) {
  @return math.div(round(($text-size + ($text-size - ($scale-percentage * $text-size))) * 100), 100);
}

@mixin allWheelTexts($scale-percentage) {
  $inner_labels: scaleWheelText($scale-percentage, $svg-inner_labels);
  #canvas_container .inner_labels text {
    font-size: $inner_labels !important;
  }
  $outerArcsTextPath: scaleWheelText($scale-percentage, $svg-outerArcsTextPath);
  @if ($outerArcsTextPath > 18) {
    // ? max 18px
    $outerArcsTextPath: 18px;
  }
  #canvas_container .outerArcsTextPath text {
    font-size: $outerArcsTextPath !important;
  }
  $escalation_icons: scaleWheelText($scale-percentage, $svg-escalation_icons);
  #canvas_container .escalation_icons text {
    font-size: $escalation_icons !important;
  }
}

// @include media-max($breakpoint-lPlus) {
//   .inner_labels text {
//     font-size: 16px !important;
//   }
//   .outerArcsTextPath text {
//     font-size: 16px !important;
//   }
//   .escalation_icons text {
//     font-size: 26px !important;
//   }
// }

// @include media-max($breakpoint-l) {
//   .inner_labels text {
//     font-size: 17px !important;
//   }
//   .outerArcsTextPath text {
//     font-size: 18px !important;
//   }
//   .escalation_icons text {
//     font-size: 28px !important;
//   }
// }

// @include media-max($breakpoint-m) {
//   .inner_labels text {
//     font-size: 18px !important;
//   }
//   .outerArcsTextPath text {
//     font-size: 19px !important;
//   }
//   // .escalation_icons text {
//   //   font-size: 28px !important;
//   // }
// }

// @include media-max($breakpoint-xs) {
//   .inner_labels text {
//     font-size: 18px !important;
//   }
//   // .outerArcsTextPath text {
//   //   font-size: 19px !important;
//   // }
// }
