$forms-under-header-margin: 0.6rem;
$table-input-border-radius: 3px;
$indicator-column-width: 60px;

.__footnote__notch {
  // ! temporary hack for field-sizing: content; expanding off screen
  display: table;
  width: 100%;
  table-layout: fixed;
  > div {
    display: table-cell;
  }
}


%empty-indicator {
  background-image: linear-gradient(
      45deg,
      #f0f0f0 8.33%,
      #ffffff 8.33%,
      #ffffff 50%,
      #f0f0f0 50%,
      #f0f0f0 58.33%,
      #ffffff 58.33%,
      #ffffff 100%
    );
    background-size: 8.49px 8.49px;
}
.forms-view {
  position: relative;
  .element-richText {
    .answer-view .view-accent {
      min-width: 50%;
    }
  }
  .element-multiLine {
    textarea.input {
      min-height: 2.8lh;
      field-sizing: content;
      padding-bottom: 0.65rem;
      max-height: 28lh;
    }
    .answer-view .view-accent {
      white-space: pre-line;
    }
  }
  .is-hidden {
    display: none;
  }
  form + form {
    margin-top: 2.4rem;
  }
  // .floating-wheel {
  //   padding: 0;
  //   position: fixed;
  //   z-index: 2;
  //   top: 7.2rem;
  //   margin-top: 2.4rem;
  //   right: 2.4rem;
  //   transform-origin: top right;
  //   border-radius: 50%;
  //   @include popover-shadow();
  //   transition: transform 0.3s ease-in-out;
  //   transform: scale(0.5) translate(85%, 0);
  //   // .arrow_left {
  //   //   position: absolute;
  //   //   left: 0;
  //   //   top: calc(50% - 50px);
  //   //   margin-left: -102px;
  //   //   font-size: 100px;
  //   //   transform: rotate(180deg);
  //   //   opacity: 0.8;
  //   //   transition: opacity 0.2s ease-out;
  //   // }
  //   .arrowAnim {
  //     opacity: 1;
  //     transition: opacity 0.2s ease-out;
  //   }
  //   &:hover {
  //     transform: scale(1) translate(0, 0);
  //     .arrowAnim {
  //       opacity: 0;
  //     }
  //   }
  // }
  .tooltip-for-brags {
    position: absolute;
    right: -26px;
    top: calc(50% - 9px);
    i.material-icons {
      font-size: 18px;
      @include customize() {
        background: getValue(table-bg);
      }
      border-radius: 50%;
      padding: 2px;
      margin: -2px;
    }
    + .input {
      margin-left: 0 !important;
    }
  }
  .tooltip-inside-brag {
    position: absolute;
    display: inline-block !important;
    width: 100%;
    height: 100%;
    text-align: right;
    left: 0;
    top: 0;
    i.material-icons {
      font-size: 14px;
      margin-top: calc(25% - 6px);
      margin-right: 4px;
    }
  }
  .form-main > div {
    + div {
      margin-top: 1.2rem;
    }
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.07),
      0 1px 0 0 rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    @include customize() {
      border: 1px solid getValue(table-border-color);
    }
    // @extend .box;
    // .form-holder {
    // border-style: solid;
    // border-top-width: 1px;
    // @include customize() {
    //   border-color: getValue(table-border-color);
    // }
    // }
  }
  .element-section {
    @include customize() {
      border-bottom: 1px solid getValue(table-border-color);
      background-color: getValue(table-thead-bg) !important;
    }
  }
  .form-children > .form-holder {
    margin: 0 1.2rem;
    border-top: 1px solid transparent;
    &:empty {
      display: none;
    }
    // + .form-holder {
    @include customize() {
      border-color: getValue(table-border-color);
    }
    &[data-ft="section"] {
      position: relative; // for shadow
      border-left-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-radius: 3px;
      box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.07),
        0 1px 0 0 rgba(0, 0, 0, 0.03);
      + .form-holder {
        border-top-width: 0;
      }
      + .form-holder[data-ft="section"] {
        margin-top: 1.2rem;
        border-top-width: 1px;
      }
      &:last-child {
        margin-bottom: 1.2rem;
      }
    }
    // }
  }
  .element {
    @include customize() {
      color: getValue(text);
      background: getValue(table-bg);
    }
    &.has-error {
      background: rgba(255, 0, 0, 0.04) !important;
    }
    // background: white;
    padding: 1.2rem;
  }

  [data-ft="section"] {
    @include customize() {
      background: getValue(table-bg);
    }
    > .form-children {
      > .form-holder {
        &:nth-child(1) {
          border-top-width: 0;
        }
        &[data-ft="section"]:nth-child(1) {
          border-top-width: 1px;
          margin-top: 1.2rem;
        }
      }
    }
  }
  // Special treatment:
  // .element-indicator {
  //   display: table;
  //   width: 100%;
  //   > * {
  //     display: table-cell;
  //     width: 50%;
  //     vertical-align: middle;
  //     @include media-max(1281px) {
  //       &:nth-child(2) {
  //         text-align: right;
  //       }
  //     }
  //   }
  // }
  // LAYOUT:
  [data-layout="horizontal"] {
    display: table;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      &:nth-child(1) {
        padding-right: 0.8rem;
      }
    }
    .radio,
    .checkbox,
    .toggle {
      display: block;
      + * {
        margin-top: 0.8rem;
        margin-left: 0 !important;
      }
    }
    // [class^="answer-"] {}
  }
  [data-layout="vertical"] {
    &.element:not(.element-section):not(.element-label) header {
      margin-bottom: $forms-under-header-margin;
    }
    &.element-checkbox,
    &.element-radio {
      .answer-new,
      [class^="answer-submit"] {
        // maybe just answer-new
        margin: 0 -0.8rem;
        .radio,
        .checkbox,
        .toggle {
          margin-left: 0.8rem;
        }
        .form-error-holder {
          padding-left: 0.8rem;
        }
      }
    }
  }
  [data-layout="hidden"] {
    > header {
      display: none;
    }
  }
  // STYLES:
  .element-gridLayout {
    > header {
      margin-bottom: $forms-under-header-margin;
    }
    .table td {
      padding: 0;
      vertical-align: middle;
      &:empty {
        padding: 0.6rem 0;
      }
      > div {
        padding: 1rem;
      }
    }
    .element {
      padding: 0 !important;
      // padding: 1rem 1.2rem;
      // margin: -1rem -1.2rem;
      // header {
      //   margin-bottom: 0 !important;
      // }
    }
    // .element-indicator > * {
    //   display: block;
    //   width: auto;
    //   text-align: left !important;
    //   margin: 1rem 1.2rem;
    // }
  }
  // ! Special indicator VIEW flex
  .element-indicator {
    .indicator {
      &__footnote__label {
        font-size: 13px;
        font-family: Helvetica, sans-serif;
        font-weight: bold;
        color: $blue;
        cursor: default;
        user-select: none;
        display: inline; // reset
        margin-bottom: 0; // reset
      }
      &__footnotes {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 0.6rem;
      }
      &__footnote {
        display: flex;
        position: relative;
        font-size: 13px;
      }
    }
    .th, .indicator__th {
      .indicator__footnote__label {
        $p: 5px;
        $ml: 1px;
        $mt: -3px;
        position: absolute;
        // margin-left: $ml;
        // margin-top: $mt;
        padding: $p;
        margin: -($p);
        margin-left: -($p) + $ml;
        margin-top: -($p) + $mt;
      }
    }
    .input-indicator-comment {
      field-sizing: content;
      min-inline-size: 50%;
      max-inline-size: 100%;
      width: auto;
      margin-top: 0; // reset
    }
    .answer-new, [class^="answer-submit"] {
      .indicator__footnote {
        align-items: center;
        .indicator__footnote__label {
          position: absolute;
          left: -6px;
          // top: 2px;
          transform: translateX(-100%);
          // + .input-indicator-comment {
          //   padding-left: 2px;
          // }
        }
      }
    }
    .answer-view {
      .indicator {
        &__footnote {
          display: flex; // overwrites
          gap: 0.8rem;
          + .view-accent {
            margin-left: 0;
          }
        }
        $indicator-border-width: 1px;
        &__box {
          margin-left: $indicator-border-width;
          // display: grid;
          // grid-template-columns: repeat(auto-fill,minmax(60px, 1fr));
          // grid-template-columns: repeat(auto-fill, minmax(min-content, 60px));
          display: flex;
          flex-wrap: wrap;
          // overflow: hidden; // for borders
          .brag-holder-padding {
            padding: 0.2rem 0.2rem;
          }
          .brag {
            width: auto;
            line-height: normal;
            border-radius: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 100%;
          }
        }
        &__wrapper {
          display: flex;
          flex-direction: column;
          max-width: $indicator-column-width;
          flex: 1 1 30px; // min width 30px for indicators
          border-width: $indicator-border-width;
          border-style: solid;
          @include customize() {
            border-color: getValue(table-border-color);
          }
          margin-left: -($indicator-border-width);
          margin-bottom: 2px;
          &:first-child {
            border-radius: 3px 0 0 0;
          }
          &:last-child {
            border-radius: 0 3px 0 0;
          }
          // .ie & {
          //   flex: 1 1 auto;
          // }
        }
        &__th {
          // display: block; // overwrite cell
          display: flex;
          align-items: flex-end;
          justify-content: center;
          text-align: center;
          flex: 1 1 auto;
          // border-top-width: 0;
          // COPY th:
          font-weight: 600;
          font-size: 1.2rem;
          @include customize() {
            background-color: getValue(table-thead-bg);
            color: getValue(text);
          }
        }
      }
    }
  }
  .table .answer-new, .table [class^="answer-submit"] {
    .indicator__footnote {
      .indicator__footnote__label {
        left: -1px;
      }
      .input-indicator-comment {
        width: 100%;
      }
    }
  }
  .answer-view {
    .view-accent {
      &:empty,
      .view-date > span:nth-child(2):empty {
        min-height: 2.7rem;
        min-width: 6.4rem;
        &:before {
          content: "(no value)";
          font-style: italic;
        }
      }
      background: tint($primary, 90%);
      padding: 0.4rem 0.8rem;
      display: inline-block;
      + .view-accent {
        margin-left: 8px;
      }
    }
    .view-date {
      display: table;
      > * {
        display: table-cell;
        vertical-align: middle;
        &:nth-child(1) {
          padding-right: 0.4rem;
        }
      }
      i.material-icons {
        font-size: 1.4rem;
        vertical-align: -0.2rem;
      }
    }
  }
  h2,
  h3 {
    margin-bottom: 0;
  }
  h3 {
    font-size: 1.3rem;
  }
  p {
    font-size: 1.2rem;
    margin-top: 0.4rem;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: auto;
  }
  .react-datepicker-wrapper,
  .react-datepicker-popper {
    + .react-datepicker-wrapper {
      margin-left: 0.6rem;
    }
  }
  .select__placeholder {
    white-space: nowrap;
  }
  [class*="-input"] {
    &.time,
    &.datetime,
    &.date {
      width: auto;
    }
    &.time {
      max-width: 7.8rem;
    }
  }
  .isRequired {
    color: $red;
    padding-left: 4px;
  }
  .th.brag-holder {
    word-break: break-word;
    word-wrap: break-word; // edge & ie11<=
    font-size: 1.1rem;
  }
  .cell.brag-holder {
    padding: 0;
    .brag {
      border-radius: 0 !important;
      height: 100%;
    }
    .input-brag {
      border-radius: 0 !important;
      border-width: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &:nth-child(1) {
      .input-brag {
        border-bottom-left-radius: $table-input-border-radius !important;
      }
    }
    &:last-child {
      .input-brag {
        border-bottom-right-radius: $table-input-border-radius !important;
      }
    }
  }
  .empty-indicator {
    @extend %empty-indicator;
  }
  .no-value-indicator {
    font-size: 0.9rem;
  }
}

.form-error-holder {
  margin-top: 0.6rem;
  font-size: 1.2rem;
  color: $red;
}

// LEXICAL:
.forms-view {
  .lexical__toolbar {
    border-width: 0;
    background: transparent;
  }
  .lexical__inner p {
    margin-top: 0;
  }
  .lexical__input > div[role=textbox] {
    min-height: 66px;
  }
  .answer-view {
    .view-accent {
      .lexical__inner {
        background: initial;
      }
      .lexical__input > div[role=textbox]:read-only {
        padding: 0;
        border-width: 0;
        background: initial;
        // @include customize() {
        //   color: getValue(text);
        // }
        // p {
        //   @include customize() {
        //     color: getValue(text);
        //   }  
        // }
      }
    }
  }
}

//

.hashId {
  color: $blue;
  font-weight: 700;
  cursor: help;
}

#report-error-summary {
  max-width: 50vw;
  text-align: left;
  border: 1px solid $red;
  padding: 1.2rem;
  background: tint($red, 95%);
  right: 24px;
  bottom: 118px;
  position: fixed;
  z-index: 3;
  color: $red;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 1px 22px 0px rgba(0, 0, 0, 0.07);
  padding-right: 4.6rem;
  .modal__close {
    top: 1rem;
    right: 1.4rem;
  }
  .list {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    &__holder {
      max-height: 30vh;
      overflow: auto;
    }
  }
  li {
    cursor: pointer;
  }
}

// .report-date-input input {
//   &:focus ~ .icon, &:not(:placeholder-shown) ~ .icon {
//     opacity: 0;
//   }
// }