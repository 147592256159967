// .ts-chart-wrapper {
  // max-width:900px;
// }

.prediction-chart-header {
  display:flex;
  margin: 0 0 12px 0;
  // justify-content: space-between;
  &-left {
    margin-right: auto;
  }
  &-right {
    margin-left: auto;
  }
  &-left, &-right {
    > * {
      display:inline-block;
      + * {
        padding-left:18px;
      }
    }
  }
}

.prediction-modal-left {
  float:left;
  width: 40%;
}

.prediction-modal-right {
  float:left;
  width: 60%;
}

#ts-predictions {
  .table .brag-holder {
    padding-left:1.2rem;
    padding-right:1.2rem;
  }
}

#indi-details {
  .form-row {
    label {
      margin-bottom: 0;
    }
    +.form-row {
      margin-top:1.8rem;
    }
    > * {
      &:first-child {
        width:100px;
      }
      &:last-child {
        font-size:1.3rem;
      }
    }
  }
  .brag {
    margin:-0.6rem 0;
  }
  &.isFetching .form-row > *:last-child {
    font-size: 1rem;
    margin-top: 6px;
    margin-bottom: 0;
  }
}
