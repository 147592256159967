// ?  "ui/_wheels" imported here

// ? To add a breakpoint:
// ? 1. Decide & store the media-max value (breakpoint stored in _breakpoints.scss)
// ? 2. Decide & add wheel size to the $wheel-sizes map/array
// ? 3. Add another generate-wheel-resizer-breakpoint() function underneath existing ones with 1. media-max, 2. wheel size name params.

$wheel-sizes: (
  xl: 600px,
  lPlus: 480px,
  l: 460px,
  m: 380px,
  xs: 320px
);
$main-wheel-padding: 8;

@function getWheelSize($value) {
  @return map-get($wheel-sizes, $value) + $main-wheel-padding;
}

// ? default max values:
.init-message {
  height: calc(#{getWheelSize(xl)} - 4.8rem);
}

.main-wheel {
  max-width: getWheelSize(xl);
  width: getWheelSize(
    xl
  ); // ! for legacy resilience (removes flicker effect when loading)
  .box {
    min-height: getWheelSize(xl);
    height: getWheelSize(xl);
    padding: 0 4px; // not sure why there is 4px difference?
  }
}
@import "ui/_wheels.scss";
// .ie {
//   .system-dashboard-layout .left {
//     min-width: getWheelSize(xl) + $ie-padding;
//     max-width: getWheelSize(xl) + $ie-padding;
//   }
// }

@mixin generate-wheel-resizer-breakpoint($media-max, $wheel-size-name) {
  $scale-percentage: strip-unit(
    math.div(percentage(math.div(strip-unit(map-get($wheel-sizes, $wheel-size-name)), 600)), 100)
  );
  @include media-max($media-max) {
    .main-wheel {
      max-width: getWheelSize($wheel-size-name);
      width: getWheelSize(
        $wheel-size-name
      ); // ! for legacy resilience (removes flicker effect when loading)
      .box {
        min-height: getWheelSize($wheel-size-name);
        height: getWheelSize($wheel-size-name);
      }
      svg {
        margin-left: math.div(map-get($wheel-sizes, $wheel-size-name) - 600, 2);
        margin-top: math.div(map-get($wheel-sizes, $wheel-size-name) - 600, 2);
        transform: scale($scale-percentage);
      }
    }
    .init-message {
      height: calc(#{getWheelSize($wheel-size-name)} - 4.8rem);
    }
    // TEXT INSIDE SVG:
    @include allWheelTexts($scale-percentage);

    //IE FIXES:
    // .ie .system-dashboard-layout .left {
    //   min-width: getWheelSize($wheel-size-name) + $ie-padding;
    //   max-width: getWheelSize($wheel-size-name) + $ie-padding;
    // }
  }
}

@include generate-wheel-resizer-breakpoint($breakpoint-lPlus, "lPlus");
@include generate-wheel-resizer-breakpoint($breakpoint-l, "l");
@include generate-wheel-resizer-breakpoint($breakpoint-m, "m");
@include generate-wheel-resizer-breakpoint($breakpoint-xs, "xs");

.mini-wheel .fake-svg-holder {
  height: 150px;
  width: 150px;
}
.fake-svg-holder {
  position: relative;
  .svg-error-icon {
    font-size: 7.2rem;
    // color: $red;
    color: rgba(0,0,0,.18);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity:0.4;
    transition: opacity .3s ease-in-out;
  }
  &:hover .svg-error-icon {
    opacity:1;
  }
}
.fake-svg {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  &.loading-element {
    margin: 0;
  }
}


.mini-wheel {
  width: calc(#{$mini-3-col} - 8px);
  &:nth-child(even) {
    margin-left: 0;
    margin-right: 0;
  }
  &:nth-child(3n),
  &:nth-child(3n-1) {
    margin-left: 10px;
  }
  // lPlus
  @include media-between($breakpoint-xs, $breakpoint-lPlus) {
    $mini-wheel-size: 110px;
    $scale-percentage-mini: strip-unit(
      math.div(percentage(math.div(strip-unit($mini-wheel-size), 150)), 100)
    );
    min-width: 13.4rem;
    // > div:first-child {
    //   width: $mini-wheel-size !important;
    //   height: $mini-wheel-size !important;
    // } // ! legacy shrewd
    // 150 - 24
    svg, .fake-svg-holder {
      // width: $mini-wheel-size !important;
      // height: $mini-wheel-size !important;
      // > g {
      //   transform: translate($mini-wheel-size / 2, $mini-wheel-size / 2)
      //     scale($scale-percentage-mini) !important;
      // }
      transform: translateX(-12px) scale($scale-percentage-mini) !important;
      transform-origin: top center;
      margin-bottom: -3.6rem;
    }
    .mini-title {
      margin-top: 0;
      h5 {
        font-size: 1.1rem;
      }
    }
  }
  @include media-max($breakpoint-m) {
    width: calc(#{$mini-2-col} - 8px);
    &:nth-child(3n),
    &:nth-child(3n-1) {
      margin-left: 0;
      margin-right: 0;
    }
    &:nth-child(even) {
      margin-left: 12px;
    }
  }
}

// ? SPECIAL BREAKPOINT:
$small-wheel-size: 68px;
$wheel-margin: 8px;
$wheel-padding: 4px;
$mini-wheels-holder-extra-space: 14px;
@include media-max($breakpoint-xs) {
  .sticky.title-in-header .system-dashboard-layout .left .scroller::before {
    opacity: 1;
  }
  .system-dashboard-layout {
    display: block;
    .main-wheel {
      display: inline-block;
    }
    .table-holder {
      height: auto;
    }
    .left,
    .right {
      flex: none;
    }
    .left .scroller {
      max-height: none !important;
      display: table;
      width: 100%;
      > * {
        display: table-cell;
        vertical-align: top;
      }
    }
    .right {
      padding-left: 0 !important;
      margin-top: 1.2rem;
    }
    .mini-wheels {
      margin: 0;
      // padding-left: 0.8rem;
    }
    .mini-wheel {
      width: 100%;
      margin: $wheel-margin 0 0 0;
      display: table;
      padding: $wheel-padding !important;
      > * {
        display: table-cell;
        vertical-align: middle;
      }
      &:first-child {
        margin-top: 0;
      }
      svg, .fake-svg-holder {
        transform: scale(
            strip-unit(math.div(percentage(math.div(strip-unit($small-wheel-size), 150)), 100))
          )
          translate(
            -($small-wheel-size + ($wheel-margin * 3)),
            -($small-wheel-size + ($wheel-margin * 3))
          );
      }
      > div:first-child {
        width: $small-wheel-size;
        height: $small-wheel-size;
        margin-bottom: 0;
      }
    }
    .mini-title {
      padding: 0 0.4rem;
    }
    .left {
      position: sticky !important;
      z-index: 1;
      top: $header-height;
      background: #f5f8fa;
      padding: 2.4rem 0 0.6rem 0;
    }
    .mini-wheels-holder {
      max-height: getWheelSize(xs) + ($mini-wheels-holder-extra-space * 2);
      overflow-x: hidden;
      overflow-y: auto;
      padding: $mini-wheels-holder-extra-space 0.8rem;
      margin-top: -($mini-wheels-holder-extra-space);
    }
    .inline-error {
      height: 120px !important;
    }
  }
  // .ie {
  //   .system-dashboard-layout {
  //     .left {
  //       top: 0;
  //       max-width: 100% !important;
  //     }
  //     .right {
  //       .inline-error {
  //         padding-top: 40px !important;
  //       }
  //       .specialScroller {
  //         max-height: 100% !important;
  //       }
  //     }
  //   }
  // }

  // .title-in-header {
  //   .system-dashboard-layout {
  //     .right {
  //       margin-top: getWheelSize(m);
  //     }
  //   }
  // }
}

// ? https://transformingsystems.atlassian.net/browse/RRP-355
// ? Edges of the mini circles cut off due to added Stroke around svgs
.mini-wheel .outerArcs > path {
  transform: scale(0.995);
}
