#report-list {
  .cell-meta__holder {
    display: flex;
    align-items: center;
  }
  .cell-date {
    @include customize() {
      color: getValue(metatext);
    }
  }
  .cell-meta__icon__holder {
    margin-right: 0.4rem;
    i.material-icons {
      font-size: 1.4rem;
    }
  }
}