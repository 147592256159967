#documents {
  .cell {
    vertical-align: text-top;
  }
  .more-holder {
    padding-left: 3.2rem !important;
    padding-right: 3.2rem !important;
    .dropdown-trigger i.material-icons {
      opacity: 0.5;
      transition: opacity 0.2s ease-out;
    }
    ul.dropdown {
      min-width: 160px;
      right: -1.2rem;
    }
  }
  .is-expand-visible {
    .expand-holder {
      i.material-icons {
        opacity: 0.3;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .expand-holder {
    padding-left: 0;
    padding-right: 0;
    i.material-icons {
      opacity: 0;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }
  }
  .description {
    // padding-left: 0.4rem;
    opacity: 0.7;
    font-size: 1.15rem;
  }
  .name-holder {
    // max-width: 1px;
    max-width: 10vw;
    cursor: pointer;
    [class^="name"] {
      font-weight: 600;
      transition: color 0.2s ease-out, text-decoration 0.2s ease-out;
    }
  }
  .description-holder {
    max-width: 1px;
    padding-right: 0;
  }
  .name-holder,
  .description-holder {
    > div {
      display: inline-block;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .is-expanded {
    .expand-holder i.material-icons {
      transform: rotate(180deg);
    }
    .name-holder,
    .description-holder {
      overflow: hidden;
      white-space: normal;
      .description,
      [class^="name"] {
        display: inline;
      }
    }
    .name-holder {
      word-break: break-word;
    }
  }
  #{unite($all-rows)} {
    &:hover {
      .more-holder .dropdown-trigger i.material-icons {
        opacity: 1;
      }
      .name-holder [class^="name"] {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
  .uploadedBy-holder,
  .uploadedOn-holder,
  .more-holder {
    @include customize() {
      color: getValue(meta-text);
    }
    white-space: nowrap;
  }
  .file-type {
    height: 14px;
    width: 22px;
    text-align: center;
    margin-right: 4px;
    img {
      height: 100%;
      vertical-align: -3px;
    }
  }
  // downloading :
  // #{unite($all-rows)} {
  //   position: relative;
  //   transform: scale(1);
  // }
  // .downloading-status {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 0;
  //   z-index: -1;
  //   height: 100%;
  //   background: rgba($primary, 0.2);
  // }
  .download-progress {
    position: absolute;
    right: 0;
    // top: calc(50% - 2px);
    > .progress { 
      width: 30px;
      height: 4px;
    }
  }
}

.document-drop {
  cursor: pointer;
  height: 120px;
  text-align: center;
  font-size: 1.2rem;
  @include verticalMiddleParent();
  i.material-icons {
    margin-top: -0.8rem;
    padding-bottom: 0.8rem;
    font-size: 4.8rem;
    color: $lightgray;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
  }
  &:hover, &.drag-active {
    background: rgba($lightgray, 0.2);
    a {
      text-decoration: underline;
    }
    i.material-icons {
      transform: translateY(-4px);
    }
  }
}

.uploaded-documents {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
  margin-top: 1.2rem;
  padding-right: 12px;
  margin-right: -12px;
  padding-bottom: 1px;
  .document {
    &:first-child {
      margin-top:0;
    }
    border-radius: $el-radius;
    @include customize() {
      border: 1px solid getValue(doc-upload-border);
      background-color: getValue(doc-upload-bg);
    }
    &.success {
      // @include customize() {
        border-color: $green;
        background-color: rgba($green, 0.2);
      // }
    }
    &.error {
      border-color: $red !important;
      background-color: rgba($red, 0.2) !important;
    }
    margin-top: 2.4rem;
    display: table;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
      padding: 1.6rem;
      &:first-child {
        padding-right: 0;
      }
      &:first-child,
      &:last-child {
        width: 1px;
      }
    }
    h6 {
      @include customize() {
        color: getValue(text);
      }
      margin-top: 0;
      // probably wont work in ie11 <:
      overflow-wrap: anywhere;
    }
    .meta {
      color: $gray;
      font-size: 1.1rem;
      margin-top: 0 !important;
    }
    i.material-icons {
      font-size: 2rem;
    }
    i.delete-icon {
      color: $gray;
      cursor: pointer;
    }
    .file-type {
      height: 100%;
      vertical-align: top;
      img {
        height: 24px;
        margin-top: 3px;
      }
    }
    + .document {
      margin-top: 1.2rem;
    }
  }
}


.on-file-over {
  .modal-cover:after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    box-shadow: inset 0 0 175px 22px rgba(65, 160, 242, 0.4);
    width: 100%;
    outline: none;
  }
  &.on-file-over-visible .modal-cover:after {
    opacity: 1;
  }
}