
.map-overlay {
  &.filters {	
    top: 24px;	
    right: 24px;	
    min-width: 260px;	
    max-width: 260px;	
  }	
  &.hospital-info {	
    top: 24px;	
    left: 24px;	
    min-width: 260px;	
    max-width: 35%; 	
    z-index: 1;	
    // max-height: calc(100vh - 120px);	
    > section {	
      max-height: 60vh;	
      max-height: calc(100vh - 168px);	
      overflow-y: auto;	
      overflow-y: overlay;	
      overflow-x: hidden;	
    }	
    .hospital-close {	
      opacity: .5;	
      transition: opacity .2s ease-in-out;	
      &:hover {	
        opacity:1;	
      }	
    }	
  }
}

// Indicator select

[class^="theme-"] .select.indicator-select {
  & #ur-map {
    margin-left: 24px;
    width: calc(100% - 24px);
  }
}

.corona-brag-table {
  // .cell {
  //   padding:0;
  // }
  display: inline-block !important;
  .tbody {
    display: flex !important;
    flex-direction: column;
  } 
  .row {
    display: flex !important;
    align-items: center;
    // flex-flow: row wrap;
  }
  .cell {
    // flex: 1;
    &:nth-child(1) {
      flex-grow: 1;
    }
    + .cell {
      flex-basis: 20px;
      flex-shrink: 0; 
    }
  }

  .cell {
    // flex: 1 1 auto !important; 
  }

  .row .cell {
    &:first-child {
      padding-left:0 !important;
    }
    &:last-child {
      padding-right:0 !important;
    }
  }
  .corona-brag-mini {
    font-size: 1.1rem;
    // width: 40px;
    width: 48px;
    height: 2.4rem;
    line-height:2.4rem;
  }
  
}


.filter-error-holder {
  opacity:0;
  transition:opacity 0.3s ease-in-out;
  &.is-visible {
    opacity:1;
  }
  > span {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    display: inline-block;
  }
}




// .map-errors {
//   //overwrites
//   width: auto;
//   // padding: 0;
//   background: #ffd3d3 !important;
//   border: 2px solid red;
//   //
//   overflow: hidden;
//   position: absolute;
//   bottom: 24px;
//   right: 24px;
//   min-width: 200px;
// }
